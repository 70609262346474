import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import EditNavbar from 'components/Navbar/EditNavbar'
import { buildTranslate } from 'locales'
import React from 'react'
import { Button } from 'react-bootstrap'
import ContentWithSave from './ContentWithSave'

interface Props {
  title?: string
  subtitle?: string
  onCancel: () => void
  onSave: () => void
  isSaving?: boolean
}

const t = buildTranslate('cms.settings.terms_and_policies')

const TermsAndPoliciesNavbar: React.FC<Props> = ({
  title,
  subtitle,
  onCancel,
  onSave,
  isSaving = false,
}) => {
  const [css] = useStyletron()

  const onPublish = () => {
    onSave()
  }

  return (
    <EditNavbar
      title={
        <>
          <Text.SmallCaps3 tag="div" className="mb-xs" color="white">
            {title}
          </Text.SmallCaps3>
          <Text.Header4 tag="div" bold color="white">
            {subtitle}
          </Text.Header4>
        </>
      }
    >
      <Button
        kind="minimal"
        bsSize="large"
        className="text-white font-normal mr-2 border-none"
        onClick={onCancel}
      >
        {t('nav.cancel')}
      </Button>
      <Button
        className={`btn-inverted ${css({
          width: '200px',
        })}`}
        bsSize="large"
        onClick={onPublish}
      >
        <ContentWithSave
          value="cms.settings.terms_and_policies.nav.save_button"
          isSaving={isSaving}
        />
      </Button>
    </EditNavbar>
  )
}

export default TermsAndPoliciesNavbar
