import { Input } from '@thanx/uikit/input'
import { Radio, RadioGroup } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import InfoTipIcon from 'assets/icons/info_tip_beta.svg'
import palette from 'constants/palette'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'

type Props = { className?: string } & PassDownProps<number | null>

const DEFAULT_FREQUENCY = 7

const t = buildTranslate(
  'thanx_campaigns.customAutomated.review.retargetFrequency'
)

function RetargetFrequencyField(props: Props) {
  const { className = '', value, setValue } = props

  const [css] = useStyletron()

  const [frequency, setFrequency] = useState(
    value ? value.toString() : DEFAULT_FREQUENCY.toString()
  )

  function handleRadioChange(toggleValue: 'retargetOff' | 'retargetOn') {
    if (toggleValue === 'retargetOff') {
      setValue(null)
    } else {
      const freqNum = Number.parseInt(frequency)
      if (!Number.isNaN(freqNum)) {
        setValue(freqNum)
      }
    }
  }

  function handleInputChange(value: string) {
    setFrequency(value)

    const numberValue = Number.parseInt(value)
    if (!Number.isNaN(numberValue)) {
      setValue(numberValue)
    }
  }

  return (
    <div className={className}>
      <RadioGroup
        className="mb-xs"
        // @ts-ignore
        onChange={handleRadioChange}
        value={!value ? 'retargetOff' : 'retargetOn'}
      >
        <Radio value="retargetOff">{t('onceLabel')}</Radio>
        <Radio value="retargetOn">{t('multipleLabel')}</Radio>
      </RadioGroup>

      {!!value && (
        <div className="ml-l">
          <div
            className={`d-flex justify-content-center align-items-start pt-m pb-m pl-xs pr-xl mb-xs ${css(
              {
                backgroundColor: palette.belizeHole10,
                width: '90%',
              }
            )}`}
          >
            <img src={InfoTipIcon} alt="Info Tip" className="ml-xs mr-xs" />
            <div>
              <Text.BodyText4 color="grey70">
                {t('warningMessage')}
              </Text.BodyText4>
            </div>
          </div>
          <Text.BodyText4 color="grey70" tag="p">
            {t('multipleDescription1')}
          </Text.BodyText4>
          <Text.BodyText4 color="grey70" tag="p" className="mb-xs">
            {t('multipleDescription2')}
          </Text.BodyText4>
          <Input
            className={css({ maxWidth: '300px' })}
            endEnhancer={t('days')}
            value={frequency.toString()}
            onChange={handleInputChange}
          />
        </div>
      )}
    </div>
  )
}

export default withFormsy(RetargetFrequencyField)
