import { Text } from '@thanx/uikit/text'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'

import { Role } from 'api/merchantUsers'
import useCurrentMerchantUser from 'hooks/useCurrentMerchantUser'
import useFlag from 'hooks/useFlag'
import CampaignPerformanceTabs, { Tabs } from './CampaignPerformanceTabs'
import EmailEngagementOverview from './EmailEngagementOverview'

const t = buildTranslate('thanx_campaigns.performance')

const CampaignPerformanceReport = () => {
  const isEmailCampaignByTypeReportEnabled = useFlag(
    'mx-email-engagement-by-campaign-looker-report',
    false
  )
  const merchant = useCurrentMerchant()
  const merchantUser = useCurrentMerchantUser()
  const [activeTab, setActiveTab] = useState<Tabs>(
    Tabs.EMAIL_ENGAGEMENT_OVERVIEW
  )

  const userIsAdmin =
    merchant && merchantUser
      ? merchantUser.roles[merchant.id].includes(Role.admin)
      : false

  const emailEngagementOverviewPath = 'dashboards/110'
  const emailEngagementByCampaignTypePath = 'dashboards/269'
  const emailEngagementOverviewHeight = 1330
  const emailEngagementByCampaignTypeHeight = 1450

  if (!merchant || !merchantUser) return null

  const emailEngagementOverviewContent = (
    <EmailEngagementOverview
      userIsAdmin={userIsAdmin}
      merchant={merchant}
      emailEngagementOverviewPath={emailEngagementOverviewPath}
      emailEngagementOverviewHeight={emailEngagementOverviewHeight}
    />
  )

  const tabContent = (
    <CampaignPerformanceTabs
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      emailEngagementOverviewContent={emailEngagementOverviewContent}
      userIsAdmin={userIsAdmin}
      merchant={merchant}
      emailEngagementByCampaignTypePath={emailEngagementByCampaignTypePath}
      emailEngagementByCampaignTypeHeight={emailEngagementByCampaignTypeHeight}
    />
  )

  const emailCampiagnContent = isEmailCampaignByTypeReportEnabled
    ? tabContent
    : emailEngagementOverviewContent

  return (
    <div className="w-100 h-100 pt-m pb-xxl px-0 white-bkg border-bottom-1 grey-20-border">
      <Container className="pt-l pb-xl px-0">
        <div className="d-flex justify-content-between">
          <Text.Header2 className="m-0 mr-xl">{t('pageTitle')}</Text.Header2>
        </div>
      </Container>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      {emailCampiagnContent}
    </div>
  )
}

export default CampaignPerformanceReport
