import HeaderCell from 'components/HeaderCell'
import { MergeTagItem } from 'utilities/mergeTags'
import MergeTagTableRow from './MergeTagTableRow'

type MergeTagTableProps = {
  mergeTagsArray: MergeTagItem[]
  onInputChange: (id: number, newValue: string) => void
}

const MergeTagTable = (props: MergeTagTableProps) => {
  const { mergeTagsArray = [], onInputChange } = props

  const tableContent = mergeTagsArray.map(mergeTag => (
    <MergeTagTableRow
      key={mergeTag.id}
      mergeTagItem={mergeTag}
      onInputChange={onInputChange}
    />
  ))

  return (
    <table className="full-width campaign-table mt-m">
      <thead>
        <tr>
          <HeaderCell key="merge_tag">Merge Tag</HeaderCell>
          <HeaderCell key="default_value">Default Value</HeaderCell>
        </tr>
      </thead>
      <tbody>{tableContent}</tbody>
    </table>
  )
}

export default MergeTagTable
