import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import back from 'assets/images/nav/icon_arrowBack.svg'
import logo from 'assets/images/nav/nav_logo.svg'
import React from 'react'
import { Navbar } from 'react-bootstrap-five'
import { I18n } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import { merchantTheme } from 'theme'

const HomeLogo: React.FC = () => {
  const [css] = useStyletron()

  const interactiveStyle = {
    marginLeft: '-120px',
    width: '240px',
    backgroundColor: merchantTheme.colors.grey100,
    color: 'white',
    textDecoration: 'none',
  }

  const handleClick = () => {
    // this is to blur the Home button. it doesn't support refs
    const activeElement = document.activeElement as HTMLElement
    if (activeElement) activeElement.blur()
  }

  return (
    <Navbar.Brand className="p-0 pr-xs m-0 overflow-hidden">
      <Link
        to="/"
        onClick={handleClick}
        className={`d-flex relative pl-m ${css({
          minHeight: '50px',
          width: '120px',
          'will-change': 'opacity, transform',
          transition: 'opacity 0.2s ease, transform 0.2s ease',
          ':hover': interactiveStyle,
          ':focus': interactiveStyle,
        })}`}
      >
        <img
          src={logo}
          alt="Thanx"
          className={`${css({
            height: '24px',
            marginTop: '10px',
          })}`}
        />
        <div
          className={`d-flex align-items-center ml-m ${css({
            width: '82px',
            paddingLeft: '12px',
          })}`}
        >
          <img src={back} alt="back" className="mr-xs" />
          <Text.BodyText3 color="white" bold>
            {I18n.t('nav.home')}
          </Text.BodyText3>
        </div>
      </Link>
    </Navbar.Brand>
  )
}

export default HomeLogo
