import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import DatePicker from 'components/Form/DatePicker'
import Input from 'components/Form/Input'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { Fields as PointsProduct } from 'models/PointsProduct'
import moment from 'moment'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormT } from './index'

type PropsT = {
  currency: string
  template: PointsProduct | null
  formTemplate: FormT | null
}

const t = buildTranslate('points.create_edit.restrictions.modal')

const DateRange: React.FC<PropsT> = props => {
  const { currency, template, formTemplate } = props
  const endAt = formTemplate?.exchange_end_at || template?.exchange_end_at
  const startAt = formTemplate?.exchange_start_at || template?.exchange_start_at

  const [showStart, setShowStart] = useState(!!startAt)
  const [showEnd, setShowEnd] = useState(!!endAt)
  const [css] = useStyletron()

  // Hard code start and end times to PST hours to align with at_start and at_end labels
  function mapStart(value?: string): string {
    return value
      ? moment(value).tz('America/Los_Angeles').startOf('day').format()
      : ''
  }

  function mapEnd(value?: string): string {
    return value
      ? moment(value).tz('America/Los_Angeles').endOf('day').format()
      : ''
  }

  return (
    <div
      className={`${css({
        width: '180px',
      })} ml-l`}
    >
      <Text.BodyText4 className="mt-m" bold tag="div">
        <span className="mr-xxs">{t('start_date')}</span>
        <PopoverContent
          sections={[
            {
              content: t('start_tooltip_1'),
            },
            {
              content: t('start_tooltip_2', { currency }),
            },
          ]}
        />
      </Text.BodyText4>
      <Switch
        condition={showStart}
        fallback={
          <Button
            bsStyle="link"
            onClick={() => setShowStart(true)}
            className="padding-none mt-xs"
            data-testid="add-start-date"
          >
            {t('add_start')}
          </Button>
        }
      >
        <DatePicker
          name="exchange_start_at"
          required={showStart}
          value={startAt}
          mapValue={mapStart}
        />
        <Text.BodyText5
          className={css({
            marginTop: '-8px',
          })}
          color="grey70"
          tag="div"
        >
          {t('at_start')}
        </Text.BodyText5>
        <Button
          bsStyle="link"
          onClick={() => setShowStart(false)}
          className="padding-none"
        >
          {t('remove')}
        </Button>
      </Switch>
      <Text.BodyText4 className="mt-m" bold tag="div">
        <span className="mr-xxs">{t('end_date')}</span>
        <PopoverContent
          sections={[
            {
              content: t('end_tooltip_1'),
            },
            {
              content: t('end_tooltip_2'),
            },
          ]}
        />
      </Text.BodyText4>
      <Switch
        condition={showEnd}
        fallback={
          <Button
            bsStyle="link"
            onClick={() => setShowEnd(true)}
            className="padding-none mt-xs"
            data-testid="add-end-date"
          >
            {t('add_end')}
          </Button>
        }
      >
        <DatePicker
          name="exchange_end_at"
          value={endAt}
          mapValue={mapEnd}
          validations={{
            greaterThanDate: startAt,
          }}
          validationErrors={{
            greaterThanDate: t('date_error'),
          }}
        />
        <Text.BodyText5
          className={css({
            marginTop: moment(endAt) < moment(startAt) ? '8px' : '-8px',
          })}
          color="grey70"
          tag="div"
        >
          {t('at_end')}
        </Text.BodyText5>
        <Button
          bsStyle="link"
          onClick={() => setShowEnd(false)}
          className="padding-none"
        >
          {t('remove')}
        </Button>
      </Switch>
      {!showStart && (
        <Input
          //@ts-ignore
          type="hidden"
          name="exchange_start_at"
          value={null}
        />
      )}
      {!showEnd && (
        <Input
          //@ts-ignore
          type="hidden"
          name="exchange_end_at"
          value={null}
        />
      )}
    </div>
  )
}

export default DateRange
