import { Text } from '@thanx/uikit/text'
import React from 'react'
import { Translate } from 'react-redux-i18n'

interface ContentProps {
  title: string
  content: string
}

const Content: React.FC<ContentProps> = ({ title, content }) => (
  <div className="d-flex flex-column justify-content-center align-items-start mb-m">
    <Text.BodyText3 className="grey90 mb-2">
      <Translate value={title} />
    </Text.BodyText3>
    <Text.BodyText4 className="grey70">{content}</Text.BodyText4>
  </div>
)

export default Content
