import {
  getDesignVariables,
  getTemplate,
} from 'actions/campaignTypes/templates'
import type { MergeTag } from 'api/mergeTag'
import Input from 'components/Form/Input'
import UnlayerWithFormsy from 'components/Form/UnlayerWithFormsy'
import Spinner from 'components/Spinner'
import { UNLAYER_VERSION } from 'constants/unlayer'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useFlag from 'hooks/useFlag'
import isEmpty from 'lodash/isEmpty'
import type { CampaignConfig, Fields as Campaign } from 'models/Campaign'
import type { Fields as Merchant } from 'models/Merchant'
import type { Fields as TemplateDesignVariable } from 'models/TemplateDesignVariable'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { selectDesignVariablesByCampaignType } from 'selectors/templateDesignVariable'
import { getUserTypes } from 'utilities/emailUtils'
import { getUnlayerMergeTags } from 'utilities/mergeTags'
import { unlayerCustomFonts } from 'utilities/unlayerCustomFonts'
import SubjectLineForm from './SubjectLineForm'

type Props = {
  campaign: Campaign
  isReloading?: boolean
  mergeTags?: MergeTag[] | undefined
  setShowMergeTagsSetupModal?: (show: boolean) => void
}

// Latest "stable" channel release as of 2024-12-12
// See https://docs.unlayer.com/docs/version-management
const unlayerProjectId =
  parseInt(process.env.REACT_APP_UNLAYER_PROJECT_ID ?? '') || undefined
const env = process.env.REACT_APP_ENVIRONMENT || 'local'
const customCSS = [
  `

    .blockbuilder-preview {
      padding: 0px !important;
    }

      button:has(svg#setup-merge-tags) {
          transition-property: background, border-color !important;
          transition-duration: 200ms !important ;
          transition-timing-function: cubic-bezier(0, 0, 1, 1) !important;
          border-radius: 4px  !important;
          background-color: transparent !important;
          letter-spacing: 0.015625rem !important;
          border-color:rgb(0, 115, 191)!important;
          border-width: 2px !important;
          border-style: solid !important;
          width: 168px !important;
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 6px;
          padding-bottom: 6px;
          span {
            color:rgb(0, 115, 191) !important;
            max-width: 150px !important;
          }
      }
    

  `
    .trim()
    .replace(/\s+/g, ' '),
]

const customJS = [
  `
 
  `,
]

export function getTemplateVariant(campaign: Campaign, config: CampaignConfig) {
  const userTypes = getUserTypes(campaign)
  const variant = config.notify_email_variant

  if (variant === 'loyalty' && userTypes.includes('subscriber')) {
    return variant
  }

  if (variant === 'reward') {
    return variant
  }

  return 'default'
}

export function getUser(merchant: Merchant | null) {
  return isEmpty(merchant)
    ? null
    : {
        id: `${env}_${merchant?.id}`,
        name: merchant?.name,
      }
}
const EmailEditor: React.FC<Props> = ({
  campaign,
  isReloading = false,
  mergeTags,
  setShowMergeTagsSetupModal,
}) => {
  const [defaultTemplate, setDefaultTemplate] = useState<null | string>(null)
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(true)
  const [isLoadingVariables, setIsLoadingVariables] = useState(true)
  const [isInitializing, setIsInitializing] = useState(true)
  const designVariables: TemplateDesignVariable[] = useSelector(state =>
    selectDesignVariablesByCampaignType(
      //@ts-ignore
      state.orm,
      campaign.type
    )
  )
  const { config } = useCampaignConfig()
  const currentMerchant = useCurrentMerchant()
  const dispatch = useDispatch()
  const mergeTagsEnabled = useFlag('mx-merge-tags', false)
  const notifyEmail = config.notify_email

  useEffect(() => {
    async function loadTemplate() {
      if (config.notify_email_configuration) {
        setIsLoadingTemplate(false)
        return
      }
      const response = await dispatch(
        getTemplate(campaign.type, getTemplateVariant(campaign, config))
      )

      //@ts-ignore
      if (!response.error) {
        //@ts-ignore
        setDefaultTemplate(response.payload.data.template.configuration)
      }
      setIsLoadingTemplate(false)
    }

    async function loadVariables() {
      // message-type campaigns fetch variables; other campaign types have
      // pre-built email configurations
      if (
        designVariables.length > 0 ||
        !isEmpty(config.notify_email_configuration)
      ) {
        setIsLoadingVariables(false)
        return
      }
      await dispatch(getDesignVariables(campaign.type))
      setIsLoadingVariables(false)
    }

    if (notifyEmail) {
      loadTemplate()
      loadVariables()
    }
    setIsInitializing(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifyEmail])

  const designTags = designVariables.reduce((tags, tag) => {
    tags[tag.name] = tag.value
    return tags
  }, {})
  const templateVariant = getTemplateVariant(campaign, config)
  const user = getUser(currentMerchant)
  const template = config.notify_email_configuration || defaultTemplate
  const unlayerMergeTags = mergeTagsEnabled
    ? getUnlayerMergeTags(mergeTags)
    : undefined
  const textEditorOptions = mergeTagsEnabled
    ? {
        customButtons: [
          {
            name: 'merge_tag_custom_button',
            text: 'Manage Merge Tags',
            icon: `
          <svg
            id="setup-merge-tags"
            data-type="setup-merge-tags"
            width="0"
            height="0"
            viewBox="0 0 0 0"
            xmlns="http://www.w3.org/2000/svg"
          />`,
            onSetup: () => {},
            onAction: () => {
              if (setShowMergeTagsSetupModal) setShowMergeTagsSetupModal(true)
            },
          },
        ],
      }
    : undefined

  return (
    <div>
      <div className="padding-top-large padding-bottom-large">
        <SubjectLineForm campaign={campaign} />
      </div>
      <Spinner
        isLoading={
          isLoadingTemplate || isLoadingVariables || isInitializing || !template
        }
        size="4x"
        className="text-center pt-xl"
      >
        <Input type="hidden" value={true} name="notify_email" />
        {!isReloading && (
          <UnlayerWithFormsy
            className="border-top-1 grey-30-border"
            name="notify_email_configuration"
            value={template}
            projectId={unlayerProjectId}
            style={{ height: 'calc(100vh - 130px)' }}
            options={{
              designTags,
              user: user ?? undefined,
              version: UNLAYER_VERSION,
              customCSS: customCSS,
              customJS: customJS,
              mergeTags: unlayerMergeTags,
              features: {
                textEditor: textEditorOptions,
              },
              tools: {
                html: {
                  enabled: true,
                },
                'custom#reward': {
                  enabled: templateVariant === 'reward',
                },
                'custom#loyalty': {
                  enabled: templateVariant === 'loyalty',
                },
              },
              fonts: {
                showDefaultFonts: true,
                customFonts: unlayerCustomFonts,
              },
            }}
          />
        )}
      </Spinner>
    </div>
  )
}

export default EmailEditor
