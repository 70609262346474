import useFlag from 'hooks/useFlag'
import urlUtils from 'utilities/urlUtils'
import CustomCcpaOut from './CustomCcpaOut'
import ThanxCcpaOut from './ThanxCcpaOut'

function CcpaOptOut() {
  const url: any = urlUtils.matchPath('/ccpaoptout/:handle', true)
  const handle = url?.params.handle

  const customizableLegalDocsFlag = useFlag('mx-customizable-legal-docs', false)

  if (customizableLegalDocsFlag && handle) {
    return <CustomCcpaOut handle={handle} testId="custom-ccpa-out" />
  }

  return <ThanxCcpaOut testId="thanx-ccpa-out" />
}

export default CcpaOptOut
