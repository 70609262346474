import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { buildBaseUrl } from './utils'

function buildBaseQuery(baseUrl: string) {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as any).auth.token
      // headers.set('Accept-Version', 'v1.0')
      headers.set('Content-Type', 'application/json')
      headers.set('Accept', process.env.REACT_APP_ACCEPT_HEADER!)

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  })
}

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // The base url is saved to the store when the app launches, so we have
  // to dynamically retrieve it for each request.
  // See https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#constructing-a-dynamic-base-url-using-redux-state
  const state = api.getState() as any

  return buildBaseQuery(buildBaseUrl(api, state))(args, api, extraOptions)
}

export const api = createApi({
  reducerPath: 'api',
  tagTypes: [
    'StoredValueMerchantConfig',
    'StoredValueUser',
    'StoredValueTransaction',
    'PointsMultiplier',
    'OnboardingSlideshow',
    'LandingPageTheme',
    'ExternalForms',
    'MenuCategories',
    'Feedback',
    'Location',
    'User',
    'Purchase',
    'TermsOfService',
    'LegalMerchants',
    'Campaign',
    'MerchantUsers',
    'Accounts',
    'LegalDoc',
    'MergeTag',
  ],
  baseQuery,
  endpoints: () => ({}),
})
