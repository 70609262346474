import React from 'react'
import Tab from 'react-bootstrap-five/Tab'

type Props = {
  state: string
  children: React.ReactNode
  className?: string
  tabClassName?: string
}

const TermsAndPoliciesTabPane: React.FC<Props> = props => {
  const { state, children, className, tabClassName } = props

  return (
    <Tab.Pane className={`grey-05-bkg ${className}`} eventKey={state}>
      <div className={`px-0 ${tabClassName}`}>{children}</div>
    </Tab.Pane>
  )
}

export default TermsAndPoliciesTabPane
