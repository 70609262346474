import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'

const t = buildTranslate('cms.settings.terms_and_policies')
type UrlUploadProps = {
  url?: string
}

const UrlUpload: React.FC<UrlUploadProps> = ({ url }) => {
  return (
    <Container className="pt-5 pb-5 px-0">
      <div className="d-flex justify-content-between mb-2">
        <Text.Header2 className="m-0 me-5">{t('url_label')}</Text.Header2>
      </div>
      <div className={`mb-xl grey90`}>
        <Text.BodyText3>{t('url_subtitle')}</Text.BodyText3>
      </div>
      <div className="w-100 d-flex flex-column gap-4">
        <Text.BodyText2 className="mb-2">{t('url_text')}</Text.BodyText2>

        <FormsyInput
          name="url"
          value={url}
          type="text"
          placeholder={t('url_placeholder')}
          className="w-100"
          validations={{
            isUrl: true,
            isRequired: true,
          }}
          validationErrors={{
            isUrl: t('url_validation_error'),
            isRequired: t('url_validation_error'),
          }}
        />
      </div>
    </Container>
  )
}

export default UrlUpload
