import ProtectedRoute from 'components/ProtectedRoute'
import useFlag from 'hooks/useFlag'
import React from 'react'
import { useSelector } from 'react-redux'
import { Switch } from 'react-router'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import Landing from './components/Landing'
import TermsAndPoliciesBuilder from './Settings/TermsAndPolicies/TermsAndPoliciesBuilder'

const Cms: React.FC = () => {
  const user = useSelector(selectCurrentMerchantUser)
  const isAdmin = user?.is_admin || false
  const customizableLegalDocsFlag = useFlag('mx-customizable-legal-docs', false)
  const isCustomizableRoutesEnabled = customizableLegalDocsFlag && isAdmin

  return (
    <div className="full-height grey-05-bkg">
      <Switch>
        <ProtectedRoute
          redirect="/thanx_campaigns"
          resource="CMS"
          exact
          path="/brand_content"
          component={Landing}
        />
        {isCustomizableRoutesEnabled && (
          <ProtectedRoute
            redirect="/thanx_campaigns"
            resource="CMS"
            exact
            path="/brand_content/terms_and_policies_builder/:legal_doc_type"
            accessType="manage"
            component={TermsAndPoliciesBuilder}
          />
        )}
      </Switch>
    </div>
  )
}

export default Cms
