import FileDownloadIcon from 'components/Icons/FileDownload'
import { formatDateString } from 'components/PointsMultiplierCard/helpers'
import React from 'react'
import { Button } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import BuilderPreviewModal from 'scenes/Cms/Settings/components/BuilderPreviewModal'
import Content from './Content'
import Card from './index'
interface CCPACardProps {
  title: string
  subtitle: string
  viewButtonText: string
  editButtonText: string
  bgColor: string
  url?: string
  lastUpdatedDate?: string
  isShowingPreview?: boolean
  onViewClick: () => void
  onEditClick: () => void
  onPreviewClose: () => void
  content?: string
  enableDownloadSampleFile?: boolean
}

const CCPACard: React.FC<CCPACardProps> = ({
  title,
  subtitle,
  viewButtonText,
  editButtonText,
  bgColor,
  url,
  lastUpdatedDate,
  isShowingPreview,
  onViewClick,
  onEditClick,
  onPreviewClose,
  content,
  enableDownloadSampleFile = false,
}) => {
  let urlContent = null as React.ReactNode | null
  if (url) {
    urlContent = (
      <Content
        title={'cms.settings.terms_and_policies.url_text'}
        content={url}
      />
    )
  }
  let lastUpdatedDateContent = null as React.ReactNode | null
  if (lastUpdatedDate) {
    lastUpdatedDateContent = (
      <Content
        title={'cms.settings.terms_and_policies.last_updated_text'}
        content={formatDateString(lastUpdatedDate, 'complete')}
      />
    )
  }

  return (
    <Card
      title={title}
      subtitle={subtitle}
      viewButtonText={viewButtonText}
      editButtonText={editButtonText}
      bgColor={bgColor}
      viewButtonOnClick={onViewClick}
      editButtonOnClick={onEditClick}
    >
      {urlContent}

      {lastUpdatedDateContent}
      {enableDownloadSampleFile && (
        <div className="d-flex flex-column justify-content-center align-items-start">
          <Button
            bsStyle="link"
            className="font-size-16 pl-0 d-flex align-items-center justify-content-center"
          >
            <FileDownloadIcon />
            <Translate value="cms.settings.terms_and_policies.ccpa.download_text" />
          </Button>
        </div>
      )}

      <BuilderPreviewModal
        show={isShowingPreview || false}
        onHide={onPreviewClose}
        content={content}
      />
    </Card>
  )
}

export default CCPACard
