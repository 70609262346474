import { buildTranslate } from 'locales'
import { I18n } from 'react-redux-i18n'

const t = buildTranslate('points.configuration.multipliers.date_range')

function dateRangeText(
  startDate: string | null,
  endDate: string | null,
  month_format?: 'abbreviation' | 'complete'
): string {
  if (!startDate && !endDate) {
    return t('on_any_date')
  }

  if (startDate === endDate) {
    return t('on', {
      date: formatDateString(startDate!, month_format),
    })
  }

  const content: string[] = []
  if (startDate) {
    content.push(
      t('from', {
        date: formatDateString(startDate, month_format),
      })
    )
  }

  if (endDate) {
    content.push(
      t('until', {
        date: formatDateString(endDate, month_format),
      })
    )
  }

  return content.join(' ')
}

export function formatDateString(
  inputDate: string,
  month_format: 'abbreviation' | 'complete' = 'abbreviation'
): string {
  const months = I18n.t(`common.months.${month_format}`)

  const dateParts = inputDate.split('-')
  const year = dateParts[0]
  const month = months[parseInt(dateParts[1]) - 1]
  const day = parseInt(dateParts[2])

  return `${month} ${day}, ${year}`
}

function formatFactor(factor: number) {
  return Number(factor)
    .toFixed(2)
    .replace(/\.?0+$/, '')
}

export { formatFactor, dateRangeText }

function parseDate(dateString?: string | null): Date {
  const date = dateString ? new Date(`${dateString}T00:00:00`) : new Date()
  date.setHours(0, 0, 0, 0)
  return date
}

export function isTodayWithinRange(
  startsOn?: string | null,
  endsOn?: string | null
): 'within' | 'past' | 'future' {
  const startDate = parseDate(startsOn)
  const endDate = parseDate(endsOn)
  const currentDate = parseDate()

  if (startDate <= currentDate && endDate >= currentDate) {
    return 'within'
  } else if (currentDate < startDate) {
    return 'future'
  } else {
    return 'past'
  }
}
