import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'
type Props = {
  htmlString: string
  enableIframe?: boolean
}

const LegalDocIframe = ({ htmlString, enableIframe = false }: Props) => {
  const [css] = useStyletron()

  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  /// this is a hack , to reset the max width of legal docs html string in a iframe
  /// when it rendered in a thanx hosted page
  const parentDiv = doc.getElementById('u_row_1')

  if (parentDiv) {
    // Find the first direct child div
    const childDiv = parentDiv.querySelector('div')
    if (childDiv) {
      childDiv.style.maxWidth = 'inherit'
    }
  }

  // Serialize the modified document back to string
  const modifiedHtml = doc.documentElement.outerHTML

  return (
    <div
      className={`legal-docs-container ${css({
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        msOverflowStyle: 'none', // Hide scrollbar in IE/Edge
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        '::-webkit-scrollbar': {
          // Hide scrollbar in Chrome/Safari/Webkit
          display: 'none',
        },
      })}`}
    >
      {enableIframe ? (
        <iframe
          title="Legal Doc Iframe"
          srcDoc={modifiedHtml}
          className={css({
            width: '100%',
            height: '100%',
            border: 'none',
            overflowY: 'hidden',
            msOverflowStyle: 'none', // Hide scrollbar in IE/Edge
            scrollbarWidth: 'none', // Hide scrollbar in Firefox
            '::-webkit-scrollbar': {
              // Hide scrollbar in Chrome/Safari/Webkit
              display: 'none',
            },
          })}
          data-testid="legal-doc-iframe"
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: modifiedHtml }} />
      )}
    </div>
  )
}

export default LegalDocIframe
