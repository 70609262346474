import useFlag from 'hooks/useFlag'
import { RouteComponentProps } from 'react-router-dom'
import BrandedTerms from './BrandedTerms'
import CustomTerms from './CustomTerms'
import SusieCakesTerms from './SusieCakesTerms'
import ThanxTerms from './ThanxTerms'
type RouteParams = {
  handle?: string
}

type Props = RouteComponentProps<RouteParams>

const customHandles = {
  susiecakes: <SusieCakesTerms />,
  thanx: <ThanxTerms />,
}

function Terms(props: Props): JSX.Element {
  const handle = props.match?.params?.handle
  const customizableLegalDocsFlag = useFlag('mx-customizable-legal-docs', false)

  if (!handle) {
    return <ThanxTerms />
  }

  if (customizableLegalDocsFlag) {
    return (<CustomTerms handle={handle} />) as JSX.Element
  }

  if (customHandles[handle]) {
    return customHandles[handle]
  }

  return <BrandedTerms handle={handle} />
}

export default Terms
