import React from 'react'
import { Container } from 'react-bootstrap-five'
import Tab from 'react-bootstrap-five/Tab'

type Props = {
  state: string
  children: React.ReactNode
  className?: string
  tabClassName?: string
}

const TabPane: React.FC<Props> = props => {
  const { state, children, className = '', tabClassName = '' } = props

  return (
    <Tab.Pane className={`py-xl grey-05-bkg ${className}`} eventKey={state}>
      <Container className={`px-0 ${tabClassName}`}>{children}</Container>
    </Tab.Pane>
  )
}

export default TabPane
