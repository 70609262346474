import { alert } from 'actions/flash'
import FileDownloadIcon from 'components/Icons/FileDownload'
import { formatDateString } from 'components/PointsMultiplierCard/helpers'
import {
  TERMS_OF_SERVICE_DOC_FILENAME,
  TERMS_OF_SERVICE_DOC_URL,
} from 'constants/policyDoc'
import useDispatch from 'hooks/useDispatch'
import { useUrlFileDownload } from 'hooks/useUrlFileDownload'
import { buildTranslate } from 'locales'
import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import BuilderPreviewModal from 'scenes/Cms/Settings/components/BuilderPreviewModal'
import Content from './Content'
import Card from './index'
interface TermsOfServiceCardProps {
  title: string
  subtitle: string
  viewButtonText: string
  editButtonText: string
  bgColor: string
  url?: string
  lastUpdatedDate?: string
  isShowingPreview?: boolean
  onViewClick: () => void
  onEditClick: () => void
  onPreviewClose: () => void
  content?: string
}

const t = buildTranslate('cms.settings.terms_and_policies')

const TermsOfServiceCard: React.FC<TermsOfServiceCardProps> = ({
  title,
  subtitle,
  viewButtonText,
  editButtonText,
  bgColor,
  url,
  lastUpdatedDate,
  isShowingPreview,
  onViewClick,
  onEditClick,
  onPreviewClose,
  content,
}) => {
  let urlContent = null as React.ReactNode | null
  if (url) {
    urlContent = (
      <Content
        title={'cms.settings.terms_and_policies.url_text'}
        content={url}
      />
    )
  }
  let lastUpdatedDateContent = null as React.ReactNode | null
  if (lastUpdatedDate) {
    lastUpdatedDateContent = (
      <Content
        title={'cms.settings.terms_and_policies.last_updated_text'}
        content={formatDateString(lastUpdatedDate, 'complete')}
      />
    )
  }

  const { downloadUrlFile, isDownloading, error } = useUrlFileDownload()
  const dispatch = useDispatch()

  const handleDownload = async () => {
    await downloadUrlFile(
      TERMS_OF_SERVICE_DOC_URL,
      TERMS_OF_SERVICE_DOC_FILENAME
    )
  }

  useEffect(() => {
    if (error) {
      dispatch(
        alert({
          key: 'danger',
          message: t('terms_of_service.sample_file_download_error'),
          timeout: 3,
        })
      )
    }
  }, [error])

  return (
    <Card
      title={title}
      subtitle={subtitle}
      viewButtonText={viewButtonText}
      editButtonText={editButtonText}
      bgColor={bgColor}
      viewButtonOnClick={onViewClick}
      editButtonOnClick={onEditClick}
    >
      {urlContent}
      {lastUpdatedDateContent}

      <div className="d-flex flex-column justify-content-center align-items-start">
        <Button
          bsStyle="link"
          className="font-size-16 pl-0 d-flex align-items-center justify-content-center"
          onClick={handleDownload}
          disabled={isDownloading}
        >
          <FileDownloadIcon />
          <Translate value="cms.settings.terms_and_policies.terms_of_service.download_text" />
        </Button>
      </div>

      <BuilderPreviewModal
        show={isShowingPreview || false}
        onHide={onPreviewClose}
        content={content}
      />
    </Card>
  )
}

export default TermsOfServiceCard
