import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

export type ApiError = {
  status: number
  data: {
    error: {
      code: string
      message: string
    }
  }
}

type BasicApiError = {
  status: number
  data: {
    error: string
  }
}

export type ErrorWithMessages = {
  status: number
  data: {
    errors: {
      messages: string[]
    }
  }
}

/**
 * Work around rtk-query returning { data: T } | { error: <ErrorTypes> }
 * from mutation triggers, which we can't narrow without a predicate.
 */
export function isError(
  response: { data: unknown } | { error: FetchBaseQueryError | SerializedError }
): response is { error: FetchBaseQueryError | SerializedError } {
  return (
    typeof response === 'object' && response !== null && 'error' in response
  )
}

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 * See https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
 */
export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error !== null && 'status' in error
}

/**
 * Type predicate to narrow an unknown error to an ErrorWithMessages object
 * Adapted from https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
 */
export function isErrorWithMessages(
  error: unknown
): error is ErrorWithMessages {
  if (typeof error !== 'object' || error === null || !('data' in error)) {
    return false
  }

  const data = (error as any).data as any
  if (data === null || typeof data !== 'object' || !('errors' in data)) {
    return false
  }

  const errorsData = data.errors
  return (
    'messages' in errorsData &&
    typeof Array.isArray(errorsData.messages) &&
    errorsData.messages.every((m: any) => typeof m === 'string')
  )
}

export function isApiError(error: unknown): error is ApiError {
  if (!isFetchBaseQueryError(error)) {
    return false
  }

  const data = error.data as any
  if (!(data !== null && typeof data === 'object' && 'error' in data)) {
    return false
  }

  const errorData = data.error
  if (typeof errorData !== 'object') {
    return false
  }
  return 'message' in errorData && typeof errorData.message === 'string'
}

function isBasicApiError(error): error is BasicApiError {
  if (!isFetchBaseQueryError(error)) {
    return false
  }

  const data = error.data as any
  if (!(data !== null && typeof data === 'object' && 'error' in data)) {
    return false
  }

  return 'error' in data && typeof data.error === 'string'
}

export function getErrorMessage(
  error: FetchBaseQueryError | SerializedError,
  defaultMessage: string
): string {
  if (isErrorWithMessages(error)) {
    return error.data.errors.messages.join(', ')
  } else if (isApiError(error)) {
    return error.data.error.message
  } else if (isBasicApiError(error)) {
    return error.data.error
  }
  return defaultMessage
}

export function buildBaseUrl(api, state: any) {
  const publicEndpoints = [
    'getLegalMerchants',
    'validateInvitationCode',
    'completeInvitation',
    'getPublicPolicy',
  ]
  if (api.endpoint === 'getTermsOfService') {
    return `${process.env.REACT_APP_API_URL}/terms_of_service`
  } else if (publicEndpoints.includes(api.endpoint)) {
    return `${process.env.REACT_APP_API_URL}`
  } else {
    return `${process.env.REACT_APP_API_URL}/merchants/${state.auth.current_merchant_id}`
  }
}
