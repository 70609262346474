import { Switch } from '@thanx/uikit/switch'
import { legalDocsApi } from 'api/legalDocs'
import { LegalDocTypeEnum } from 'api/legalDocs'
import Loading from 'components/Loading'
import React from 'react'
import LegalDocIframe from './LegalDocIframe'
import ThanxCcpaOut from './ThanxCcpaOut'

type Props = {
  handle: string
  testId?: string
}

const CustomCcpaOut = ({ handle, testId }: Props) => {
  const {
    data: { legal_doc: ccpaOptOutDoc } = {},
    isLoading: isLoadingCcpaOptOutDoc,
    isFetching: isFetchingCcpaOptOutDoc,
    isSuccess: isSuccessCcpaOptOutDoc,
  } = legalDocsApi.useGetPublicPolicyQuery({
    legal_doc_type: LegalDocTypeEnum.CCPA,
    merchant_handle: handle || '',
  })

  if (isLoadingCcpaOptOutDoc || isFetchingCcpaOptOutDoc) {
    return <Loading />
  }

  if (!ccpaOptOutDoc) {
    return <ThanxCcpaOut />
  }

  if (ccpaOptOutDoc && !ccpaOptOutDoc.thanx_hosted) {
    window.location.href = ccpaOptOutDoc.url || ''
    return null // Return null while redirecting
  }

  let content: React.ReactNode = null

  if (ccpaOptOutDoc && ccpaOptOutDoc.thanx_hosted) {
    content = <LegalDocIframe htmlString={ccpaOptOutDoc.html || ''} />
  }

  return (
    <Switch
      condition={isSuccessCcpaOptOutDoc}
      fallback={<ThanxCcpaOut />}
      data-testid={testId}
    >
      {content}
    </Switch>
  )
}

export default CustomCcpaOut
