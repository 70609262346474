import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import React from 'react'

type Props = {
  className?: string
  title: string
  subtitle?: string
  children: React.ReactNode
  viewButtonText?: string
  editButtonText?: string
  bgColor?: string
  viewButtonOnClick?: () => void
  editButtonOnClick?: () => void
}

const Card: React.FC<Props> = ({
  className = 'mb-m',
  title,
  subtitle,
  viewButtonText,
  viewButtonOnClick,
  editButtonText,
  editButtonOnClick,
  bgColor = palette.grey10,
  children,
}) => {
  const [css] = useStyletron()

  return (
    <div
      className={`card p-m ${className} 
      ${css({
        backgroundColor: bgColor,
      })}
      `}
    >
      <div className={`d-flex align-items-center justify-content-between`}>
        <h4 className="extra-bold mr-xs">{title}</h4>
        <div className="d-flex align-items-center">
          {viewButtonText && (
            <Button
              className={css({
                height: '32px',
                fontSize: '14px',
                marginLeft: '16px',
              })}
              kind="secondary"
              onClick={viewButtonOnClick}
            >
              {viewButtonText}
            </Button>
          )}
          {editButtonText && (
            <Button
              className={css({
                height: '32px',
                fontSize: '14px',
                marginLeft: '16px',
              })}
              kind="primary"
              onClick={editButtonOnClick}
            >
              {editButtonText}
            </Button>
          )}
        </div>
      </div>
      <div className={`mb-m grey90`}>
        <Text.BodyText4>{subtitle}</Text.BodyText4>
      </div>
      {children}
    </div>
  )
}

export default Card
