import { useStyletron } from '@thanx/uikit/theme'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useDispatch from 'hooks/useDispatch'
import React from 'react'
import { Button } from 'react-bootstrap'
import Tab from 'react-bootstrap-five/Tab'
import { Translate } from 'react-redux-i18n'
import { RouteComponentProps } from 'react-router-dom'
import { goBack, replace } from 'react-router-redux'
import BuilderPreviewModal from 'scenes/Cms/Settings/components/BuilderPreviewModal'
import PublishAndCancel from 'scenes/Cms/Settings/components/PublishAndCancel'
import TermsAndPoliciesTabPane from 'scenes/Cms/Settings/components/TermsAndPoliciesTabPane'
import UrlUpload from 'scenes/Cms/Settings/UrlUpload'
import TermsAndPoliciesNavbar from './TermsAndPoliciesNavbar'

import {
  legalDocsApi,
  LegalDocTypeEnum,
  type LegalDoc,
  type LegalDocType,
} from 'api/legalDocs'
import Flash from 'components/Flash'
import { UNLAYER_VERSION } from 'constants/unlayer'
import Formsy from 'formsy-react'
import { buildTranslate } from 'locales'
import UnlayerDocBuilderWithFormsy from 'scenes/Cms/Settings/TermsAndPolicies/components/UnlayerDocBuilderWithFormsy'
import { getSubtitle, Tabs } from 'scenes/Cms/Settings/TermsAndPolicies/helpers'
import { useTermsAndPoliciesBuilder } from 'scenes/Cms/Settings/TermsAndPolicies/hooks/useTermsAndPoliciesBuilder'

const unlayerProjectId =
  parseInt(process.env.REACT_APP_UNLAYER_PROJECT_ID ?? '') || undefined
const displayMode = 'email'
const customCSS = [
  `
    .blockbuilder-preview {
      padding: 0px !important;
    }      
  `
    .trim()
    .replace(/\s+/g, ' '),
]

type Props = RouteComponentProps<{
  legal_doc_type: string
}>

const t = buildTranslate('cms.settings.terms_and_policies')
const TermsAndPoliciesBuilder: React.FC<Props> = ({ match }) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const { legal_doc_type: legalDocType } = match.params

  if (
    legalDocType !== LegalDocTypeEnum.PRIVACY_POLICY &&
    legalDocType !== LegalDocTypeEnum.TERMS_OF_SERVICE &&
    legalDocType !== LegalDocTypeEnum.CCPA
  ) {
    dispatch(replace('/'))
  }

  const { data: { legal_docs: legalDocs } = { legal_docs: [] } } =
    legalDocsApi.useGetLegalDocsQuery()
  const policyDoc: LegalDoc | undefined = legalDocs.find(
    doc => doc.legal_doc_type === legalDocType
  )
  const subtitle = getSubtitle(legalDocType as LegalDocType)

  const {
    docContentForm,
    urlForm,
    setIsDocContentFormValid,
    activeTab,
    setActiveTab,
    showPreviewModal,
    setShowPreviewModal,
    showPublishModal,
    setShowPublishModal,
    handleFormChange,
    handlePublish,
    isFormSaving,
    isPreviewing,
    previewData,
    previewLegalDoc,
  } = useTermsAndPoliciesBuilder(policyDoc, legalDocType as LegalDocType)

  const handleCancel = () => {
    dispatch(goBack())
  }

  const onContinue = () => {
    let form =
      activeTab === Tabs.PROVIDE_LEGAL_DOC_URL
        ? urlForm.current
        : docContentForm.current
    if (form) {
      form.validateForm()
      form?.submit()
    }
  }

  const onValidSubmit = () => {
    setShowPublishModal(true)
  }

  const handlePreview = () => {
    setShowPreviewModal(true)
    if (docContentForm.current) {
      previewLegalDoc({
        configuration: docContentForm.current.getModel().documentContent,
      })
    }
  }

  return (
    <>
      <div
        className={`w-100 position-fixed ${css({
          top: 0,
          left: 0,
          zIndex: 1001,
        })}`}
      >
        <Flash />
      </div>

      <div>
        <div
          className={`${css({
            marginTop: '30px',
          })}`}
        >
          <TermsAndPoliciesNavbar
            onCancel={handleCancel}
            onSave={onContinue}
            isSaving={isFormSaving}
            title={t('title')}
            subtitle={subtitle}
          />
          <div className="bg-white">
            <Tab.Container
              activeKey={activeTab}
              onSelect={key => setActiveTab(key as Tabs)}
            >
              <TabNav
                className={css({
                  maxWidth: 'none',
                  width: '100%',
                  paddingLeft: 0,
                  paddingRight: '24px',
                  marginLeft: 0,
                  marginRight: 0,
                })}
              >
                <TabItem
                  state={Tabs.ENTER_CUSTOM_LEGAL_DOC_TEXT}
                  title={t('tabs.enter_custom_text_title')}
                />
                <TabItem
                  state={Tabs.PROVIDE_LEGAL_DOC_URL}
                  title={t('tabs.provide_a_url_title')}
                />

                {activeTab === Tabs.ENTER_CUSTOM_LEGAL_DOC_TEXT && (
                  <div className="flex-1 d-flex justify-content-end">
                    <Button
                      bsStyle="link"
                      className="font-size-16"
                      onClick={handlePreview}
                    >
                      <i className="fa fa-eye margin-right-small" />
                      <Translate value="cms.settings.terms_and_policies.tabs.preview" />
                    </Button>
                  </div>
                )}
              </TabNav>

              <Tab.Content>
                <TermsAndPoliciesTabPane
                  state={Tabs.ENTER_CUSTOM_LEGAL_DOC_TEXT}
                  tabClassName="m-0"
                >
                  <Formsy
                    ref={docContentForm}
                    onValidSubmit={onValidSubmit}
                    onChange={handleFormChange}
                    onValid={() => setIsDocContentFormValid(true)}
                    onInvalid={() => setIsDocContentFormValid(false)}
                  >
                    <div className="px-xl">
                      <UnlayerDocBuilderWithFormsy
                        name={`documentContent`}
                        projectId={unlayerProjectId}
                        value={policyDoc?.configuration}
                        style={{ height: 'calc(100vh - 150px)' }}
                        options={{
                          displayMode,
                          projectId: unlayerProjectId,
                          version: UNLAYER_VERSION,
                          customCSS: customCSS,
                          locale: 'en',
                          tools: {
                            button: {
                              enabled: false,
                            },
                            social: {
                              enabled: false,
                            },
                            menu: {
                              enabled: false,
                            },
                            content: {
                              enabled: false,
                            },
                            customTool: {
                              enabled: false,
                            },
                            // disable loyalty tool
                            'custom#loyalty': {
                              enabled: false,
                            },
                            'custom#reward': {
                              enabled: false,
                            },
                          },
                        }}
                      />
                    </div>
                  </Formsy>
                </TermsAndPoliciesTabPane>
                <TabPane state={Tabs.PROVIDE_LEGAL_DOC_URL}>
                  <Formsy
                    ref={urlForm}
                    onChange={handleFormChange}
                    onValidSubmit={onValidSubmit}
                  >
                    <UrlUpload url={policyDoc?.url || ''} />
                  </Formsy>
                </TabPane>
              </Tab.Content>
            </Tab.Container>
          </div>

          <BuilderPreviewModal
            show={showPreviewModal}
            onHide={() => setShowPreviewModal(false)}
            content={previewData?.html || ''}
            isLoading={isPreviewing}
          />
          <PublishAndCancel
            show={showPublishModal}
            onHide={() => setShowPublishModal(false)}
            triggerPublish={handlePublish}
          />
        </div>
      </div>
    </>
  )
}

export default TermsAndPoliciesBuilder
