import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import InfoTipIcon from 'assets/icons/info_tip_beta.svg'
import Section from 'components/Section'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import React from 'react'
import { getFormattedDate } from 'utilities/date'
import { useUserTimeZone } from 'utilities/userTimeZone'
const t = buildTranslate('thanx_campaigns.report.v3')

type Props = {
  startAt: string
  endAt: string | null
  type: string
  retargetFrequency?: number | null
}

const ScheduleSection: React.FC<Props> = ({
  startAt,
  endAt,
  type,
  retargetFrequency,
}) => {
  const timeZone = useUserTimeZone()
  const [css] = useStyletron()

  const startDate = getFormattedDate(moment.tz(startAt, timeZone), 'daily')
  const startTime = moment.tz(startAt, timeZone).format('hh:mma z')
  const endDate = getFormattedDate(moment.tz(endAt, timeZone), 'daily')
  const endTime = moment.tz(endAt, timeZone).format('hh:mma z')
  return (
    <Section className="p-xl">
      <Text.Header4 className="m-0 mb-l">
        {t('configuration.schedule')}
      </Text.Header4>
      <Text.BodyText4 tag="p" color="grey90" bold className="m-0 mb-xs">
        {t('configuration.start')}
      </Text.BodyText4>

      <Text.BodyText4 tag="p" color="grey70" className="m-0">
        {startDate}
      </Text.BodyText4>

      <Text.BodyText4 tag="p" color="grey70" className="m-0 mb-m">
        {startTime}
      </Text.BodyText4>
      <Switch condition={type === 'custom_automated'}>
        <Text.BodyText4 tag="p" color="grey90" bold className="m-0 mb-xs">
          {t('configuration.end')}
        </Text.BodyText4>
        <Switch
          condition={!!endAt}
          fallback={
            <Text.BodyText4 tag="p" className="m-0" color="grey70">
              {t('no_end_date')}
            </Text.BodyText4>
          }
        >
          <Text.BodyText4 tag="p" color="grey70" className="m-0">
            {endDate}
          </Text.BodyText4>
          <Text.BodyText4 tag="p" color="grey70" className="m-0">
            {endTime}
          </Text.BodyText4>
        </Switch>
        <Text.BodyText4 tag="p" color="grey90" bold className="m-0 mb-xs mt-l">
          {t('configuration.recurring.title')}
        </Text.BodyText4>
        <Switch
          condition={
            retargetFrequency !== null && retargetFrequency !== undefined
          }
          fallback={
            <Text.BodyText4 tag="p" color="grey70" className="m-0">
              {t('configuration.recurring.once')}
            </Text.BodyText4>
          }
        >
          <Text.BodyText4 tag="p" color="grey70" className="m-0">
            {t('configuration.recurring.multiple', {
              count: retargetFrequency,
            })}
          </Text.BodyText4>
          <Text.BodyText4 tag="p" color="grey70" className="m-0">
            {t('configuration.recurring.multiple_text', {
              count: retargetFrequency,
            })}
          </Text.BodyText4>
          <div
            className={`d-flex justify-content-center align-items-start pt-m pb-m pl-m pr-xl mt-m ${css(
              {
                backgroundColor: palette.belizeHole10,
              }
            )}`}
          >
            <img src={InfoTipIcon} alt="Info Tip" className="ml-xs mr-xs" />
            <div>
              <Text.BodyText4 color="grey70">
                {t('configuration.recurring.warningMessage')}
              </Text.BodyText4>
            </div>
          </div>
        </Switch>
      </Switch>
    </Section>
  )
}

export default ScheduleSection
