import { useStyletron } from '@thanx/uikit/theme'
import Spinner from 'components/Spinner'
import LegalDocIframe from 'public/Privacy/LegalDocIframe'
import React from 'react'
import { Button } from 'react-bootstrap'
import { Modal } from 'react-bootstrap-five'

interface BuilderPreviewModalProps {
  show: boolean
  onHide: () => void
  content?: string
  isLoading?: boolean
  width?: string
}

const BuilderPreviewModal: React.FC<BuilderPreviewModalProps> = ({
  show,
  onHide,
  content = '',
  isLoading = false,
  width = '600px',
}) => {
  const [css] = useStyletron()

  let iframeContent: React.ReactNode | null = null

  if (content) {
    iframeContent = <LegalDocIframe htmlString={content} enableIframe={true} />
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      zIndex={1000}
      centered
      className="fs-unmask"
      dialogClassName={css({
        width: '90vw',
        maxWidth: '90vw',
        height: '90vh',
        maxHeight: '90vh',
      })}
    >
      <Modal.Header
        className={css({
          padding: '30px',
          position: 'relative',
          border: 'none',
        })}
      >
        <div
          className={css({
            position: 'absolute',
            right: '10px',
            top: '10px',
            textAlign: 'center',
          })}
        >
          <span
            className={css({
              fontSize: '32px',
              color: '#666',
              fontWeight: 'lighter',
              cursor: 'pointer',
            })}
            onClick={onHide}
          >
            &times;
          </span>
        </div>
      </Modal.Header>
      <Modal.Body
        className={css({
          height: 'calc(90vh - 140px)', // Account for header and footer height
          padding: 0,
          display: 'flex',
        })}
      >
        <div className="fs-unmask p-m justify-content-center align-items-center w-100">
          {isLoading ? (
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              })}
            >
              <Spinner isLoading={true} />
            </div>
          ) : (
            <div
              className={css({
                overflowX: 'scroll',
                height: '100%',
                '::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome/Safari/Opera
                msOverflowStyle: 'none', // Hide scrollbar for IE/Edge
                scrollbarWidth: 'none', // Hide scrollbar for Firefox
              })}
            >
              <div
                className={`iframe-builder-preview ${css({
                  minWidth: width,
                  width: '100%',
                  height: '100%',
                })}`}
              >
                {iframeContent}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        className={css({
          border: 'none',
          paddingLeft: '48px',
          paddingRight: '48px',
        })}
      >
        <Button
          bsStyle="primary"
          bsSize="large"
          className="pull-right"
          type="submit"
          onClick={onHide}
        >
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BuilderPreviewModal
