import { Switch } from '@thanx/uikit/switch'
import { legalDocsApi } from 'api/legalDocs'
import { LegalDocTypeEnum } from 'api/legalDocs'
import Loading from 'components/Loading'
import React from 'react'
import LegalDocIframe from './LegalDocIframe'
import ThanxPrivacy from './ThanxPrivacy'

type Props = {
  handle: string
  testId?: string
}

const CustomPrivacy = ({ handle, testId }: Props) => {
  const {
    data: { legal_doc: privacyPolicyDoc } = {},
    isLoading: isLoadingPrivacyPolicyDoc,
    isFetching: isFetchingPrivacyPolicyDoc,
    isSuccess: isSuccessPrivacyPolicyDoc,
  } = legalDocsApi.useGetPublicPolicyQuery({
    legal_doc_type: LegalDocTypeEnum.PRIVACY_POLICY,
    merchant_handle: handle || '',
  })

  if (isLoadingPrivacyPolicyDoc || isFetchingPrivacyPolicyDoc) {
    return <Loading />
  }

  if (!privacyPolicyDoc) {
    return <ThanxPrivacy handle={handle} />
  }

  if (privacyPolicyDoc && !privacyPolicyDoc.thanx_hosted) {
    window.location.href = privacyPolicyDoc.url || ''
    return null // Return null while redirecting
  }
  let content: React.ReactNode = null

  if (privacyPolicyDoc && privacyPolicyDoc.thanx_hosted) {
    content = <LegalDocIframe htmlString={privacyPolicyDoc.html || ''} />
  }

  return (
    <Switch
      data-testid={testId}
      condition={isSuccessPrivacyPolicyDoc}
      fallback={<ThanxPrivacy handle={handle} />}
    >
      {content}
    </Switch>
  )
}

export default CustomPrivacy
