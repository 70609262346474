import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { useStyletron } from 'styletron-react'

type Props = {
  triggerPublish: () => Promise<void>
  show: boolean
  onHide: () => void
}

const PublishAndCancel: React.FC<Props> = ({
  triggerPublish,
  show,
  onHide,
}) => {
  const [css] = useStyletron()

  const exit = () => {
    onHide()
  }

  const publish = () => {
    triggerPublish()
    onHide()
  }

  return (
    <Modal className="fs-unmask" show={show} onHide={onHide} zIndex={1000}>
      <Modal.Body>
        <div className="clearfix">
          <div className="pull-right">
            <Button
              onClick={onHide}
              className="btn-borderless padding-right-none"
            >
              <span className="font-size-40 line-height-30 grey-60 light">
                &times;
              </span>
            </Button>
          </div>
        </div>

        <Translate
          value={'cms.settings.terms_and_policies.modal.title'}
          tag="h6"
        />

        <Translate
          tag="div"
          className="width-75 margin-bottom-huge grey-70"
          value={'cms.settings.terms_and_policies.modal.body'}
        />
        <div className="text-right">
          <Button
            bsSize="large"
            bsStyle="info"
            className={`margin-right-medium ${css({
              border: '2px solid',
              borderColor: 'inherit',
              minWidth: '100px',
            })}`}
            onClick={exit}
          >
            <Translate
              value={'cms.settings.terms_and_policies.modal.buttons.cancel'}
            />
          </Button>

          <Button
            onClick={publish}
            bsStyle="primary"
            bsSize="large"
            className={css({
              minWidth: '100px',
            })}
          >
            <Translate
              value={'cms.settings.terms_and_policies.modal.buttons.publish'}
            />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PublishAndCancel
