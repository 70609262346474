import smart_links from './smart_links'

export default {
  smart_links,
  ordering_configuration: {
    title: 'Ordering configuration',
    description:
      'A large amount of information comes directly from your ordering provider which Thanx then displays. Information from your ordering provider includes:',
    image_alt: 'Ordering config',
    contact_your_provider:
      'Contact your ordering provider to update your ordering configuration. Changes with your provider may take a few moments to be reflected in your ordering experience.',
    info: 'Info',
    list: {
      menu_item: 'Menu item images',
      category_order: 'Category order',
      ordering_categories: 'Ordering categories, items, modifiers',
      pricing: 'Pricing',
      handoff: 'Handoff mode configuration (delivery, pickup, etc.)',
      location_availability: 'Location availability',
      location_hours: 'Location hours',
    },
    not_enabled_page: {
      title: 'Your Thanx account is not integrated with online ordering',
      description:
        'Supercharge your customer database with Thanx-powered digital ordering integrations. Thanx offers a variety of ways to integrate, whether it’s working with your current setup or creating a seamless transition to our native ordering experience. We offer robust app and web configurations to best suit your needs.',
      contact_our_team: 'Contact our team to learn about Thanx Ordering',
      image_alt:
        'Example of Thanx Ordering for web (right) and native app (left)',
    },
  },
  receipt_settings: {
    title: 'Receipt settings',
    description:
      'Customers can upload receipts in your app to earn reward progress. You may customize which type of receipts are accepted. See your current configuration below.<br/><br/>Need to update your receipt settings? <a href="mailto:merchant.support@thanx.com" target="_blank">Contact us</a>',
    receipt_type: 'Receipt type',
    accepted: 'Accepted?',
    cash: 'Cash receipts',
    gift_card: 'Gift card receipts',
    online: 'Online receipts',
    third_party: '3rd party delivery receipts',
    yes: 'Yes',
    no: 'No',
    store_credit: 'Store credit',
  },
  terms_and_policies: {
    title: 'Terms and policies',
    description:
      'Manage the terms and policy documents that customers agree to when creating an account.',
    sidebar: {
      modal: {
        publish_changes: 'Publish changes',
        you_are_publishing:
          'You are publishing changes to <strong>Terms and policies</strong><br/>Publishing these changes will push them live to your app.',
        publish: 'Yes, publish changes',
      },
    },
    nav: {
      title: 'Configuration',
      sub_title: 'Terms and Policies Builder',
      save_button: 'Publish',
      saving: 'Saving ...',
      cancel: 'Cancel',
    },
    modal: {
      title: 'Publish this document',
      body: `Any changes you've made will be updated immediately.`,
      buttons: {
        publish: 'Publish',
        cancel: 'Cancel',
      },
    },
    tabs: {
      enter_custom_text: 'Enter Custom Text',
      provide_a_url: 'Provide a URL',
      preview: 'Preview',
      enter_custom_text_title: 'COPY/PASTE A FILE',
      provide_a_url_title: 'LINK TO URL',
    },
    custom_text: 'You are using custom text',
    url_custom_text: 'You are using a URL',
    last_updated_text: 'Last updated',
    url_text: 'URL',
    url_label: 'Link a hosted document',
    url_subtitle:
      'Use this option if you want to host your own legal document.',
    url_placeholder: 'Enter a URL',
    url_validation_error: `Please enter a valid URL like (https://www.example.com/)`,
    privacy_policy: {
      title: 'Privacy policy',
      default_subtitle: 'You are using default privacy policy text',
      download_text: 'Download a sample privacy policy document',
      view_btn_text: 'View',
      edit_default_btn_text: 'Manage your custom privacy policy',
      edit_btn_text: 'Edit',
      sample_file_download_error:
        'Failed to download sample privacy policy document',
    },
    terms_of_service: {
      title: 'Terms of service',
      default_subtitle: 'You are using default terms of service text',
      download_text: 'Download a sample terms of service document',
      view_btn_text: 'View',
      edit_default_btn_text: 'Manage your custom terms of service',
      edit_btn_text: 'Edit',
      sample_file_download_error:
        'Failed to download sample terms of service document',
    },
    ccpa: {
      title: 'CCPA',
      default_subtitle: 'You are using default CCPA text',
      download_text: 'Download a sample CCPA document',
      view_btn_text: 'View',
      edit_default_btn_text: 'Manage your custom CCPA',
      edit_btn_text: 'Edit',
      sample_file_download_error: 'Failed to download sample CCPA document',
    },
    errors: {
      load_error:
        'There was an error loading your terms and policies. Please try again.',
      documentbuilder: {
        image_upload_error:
          'Something went wrong uploading your image. Please try again.',
        load_error:
          'There was an error loading the document builder. Please ensure any ad blockers are disabled and your browser is configured to allow third-party cookies.',
        preview_error:
          'There was an error loading the preview. Please try again.',
        publish_error:
          'There was an error publishing your changes. Please try again.',
        save_error: 'There was an error saving your changes. Please try again.',
        create_error:
          'There was an error creating your document. Please try again.',
      },
    },
  },
  analytics: {
    tab: 'Facebook and Google Analytics',
    enabled_title: 'Facebook and Google Analytics (for online ordering)',
    enabled_description:
      'Measure every step of your online ordering funnel — including conversions, basket abandonment, SKU level data and more. Use this data to attribute real ROI from online orders to your marketing campaigns on Facebook, Google, or any ad platform supported by Google Tag Manager.<br /><br />Connect the tools below to start tracking ordering conversion metrics.',
    disabled_title: 'Your Thanx account is not integrated with online ordering',
    disabled_description:
      'An online ordering integration is required to connect to Facebook and Google Analytics.<br /><br />Supercharge your customer database with Thanx-powered digital ordering integrations. Thanx offers a variety of ways to integrate, whether its working with your current setup or creating a seamless transition to our native ordering experience. We offer robust app and web configurations to best suit your needs. <br /><br />Then, measure every step of your online ordering funnel—including conversions, basket abandonment, SKU level data and more. Use this data to attribute real ROI from online orders to your Facebook and Google marketing campaigns.',
    where_to_text: 'Where to find this ID',
    example: 'Example of Thanx Ordering for web (right) and native app (left)',
    contact_disabled:
      'Contact our team to learn about Thanx ordering and analytics',
    how_to_text: 'How to set up %{title}',
    connect: 'Connect',
    update: 'Update',
    contact: {
      title: 'Need to track your app data with Facebook or Google Analytics?',
      description:
        'Contact us to learn how to connect your app to your tracking tools',
      button: 'Contact us',
    },
    processing: 'Processing %{label}',
    successful: 'Your update to %{label} has been processed successfully',
    options: {
      facebook_pixel_id: {
        title: 'Facebook Pixel',
        description:
          'Track your online ordering end-to-end funnel metrics with Facebook.',
        label: 'Pixel ID',
      },
      google_measurement_id: {
        title: 'Google Analytics',
        description:
          'Track your online ordering end-to-end funnel metrics with Google Analytics.',
        label: 'Google Measurement ID',
      },
      google_tag_manager_id: {
        title: 'Google Tag Manager',
        description:
          'Track marketing to online conversion across all your third-party vendors.<br />Manage tags for conversion tracking, site analytics, remarketing and more.',
        label: 'Container ID',
      },
    },
  },
}
