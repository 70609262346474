import { Switch } from '@thanx/uikit/switch'
import { legalDocsApi, LegalDocTypeEnum } from 'api/legalDocs'
import Loading from 'components/Loading'
import LegalDocIframe from 'public/Privacy/LegalDocIframe'
import React from 'react'
import ThanxTerms from './ThanxTerms'

type Props = {
  handle: string
}

export default function CustomTerms({ handle }: Props): JSX.Element | null {
  const {
    data: { legal_doc: termsOfServiceDoc } = {},
    isLoading: isLoadingTermsOfServiceDoc,
    isFetching: isFetchingTermsOfServiceDoc,
    isSuccess: isSuccessTermsOfServiceDoc,
  } = legalDocsApi.useGetPublicPolicyQuery({
    legal_doc_type: LegalDocTypeEnum.TERMS_OF_SERVICE,
    merchant_handle: handle || '',
  })

  if (isLoadingTermsOfServiceDoc || isFetchingTermsOfServiceDoc) {
    return <Loading />
  }

  let content: React.ReactNode = null

  if (!termsOfServiceDoc) {
    content = <ThanxTerms testId="thanx-terms" />
  }

  if (termsOfServiceDoc && !termsOfServiceDoc.thanx_hosted) {
    window.location.href = termsOfServiceDoc.url || ''
    return null // Return null while redirecting
  }

  if (termsOfServiceDoc && termsOfServiceDoc.thanx_hosted) {
    content = <LegalDocIframe htmlString={termsOfServiceDoc.html || ''} />
  }

  return (
    <Switch
      condition={isSuccessTermsOfServiceDoc}
      fallback={<ThanxTerms data-testid="thanx-terms" />}
    >
      {content}
    </Switch>
  )
}
