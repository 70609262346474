import {
  AuthorizeAction,
  IMPERSONATE_USER_SUCCESS,
  PASSWORD_AUTH_SUCCESS,
  RESET_PASSWORD_SUCCESS,
} from 'actions/auth'
import {
  GET_MERCHANT_USER_SUCCESS,
  INSERT_AUTH_MERCHANT_USER,
  ResolvedAction,
  UPDATE_MERCHANT_USER_SUCCESS,
} from 'actions/merchantUser'
import { RESET_DB } from 'actions/orm'
import { attr, Model, ModelType } from 'redux-orm'

import { Fields as CampaignFields } from './Campaign'

export type Role =
  | 'admin'
  | 'manager'
  | 'approver'
  | 'accountant'
  | 'customer_service_read'
  | 'customer_service_write'
  | 'mall'

type NPSEmails = {
  id: number
  gets_nps_emails: boolean
  merchant_name: string
}

export interface Fields {
  id: number
  first_name: string
  last_name: string
  time_zone: string
  email: string
  is_admin: boolean
  roles: Record<number, Role[]>
  phone?: string[]
  title?: string
  gets_nps_emails?: NPSEmails[]
  force_password_change?: boolean
  rolesForMerchantId: (number) => Role[]
  zendesk_jwt?: string
  zendesk_sso_jwt?: string
  role: Role
}

export default class MerchantUser extends Model<typeof MerchantUser, Fields> {
  static modelName: string = 'MerchantUser'

  static get fields(): any {
    return {
      id: attr(),
      first_name: attr(),
      last_name: attr(),
      time_zone: attr(),
      email: attr(),
      is_admin: attr(),
      roles: attr(),
      // merchants: many('Merchant', 'merchants')
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | AuthorizeAction | { type: typeof RESET_DB },
    MerchantUser: ModelType<MerchantUser>
  ) {
    switch (action.type) {
      case INSERT_AUTH_MERCHANT_USER:
        MerchantUser.upsert(action.merchant_user)
        break
      case PASSWORD_AUTH_SUCCESS:
      case RESET_PASSWORD_SUCCESS:
      case IMPERSONATE_USER_SUCCESS:
      case UPDATE_MERCHANT_USER_SUCCESS:
      case GET_MERCHANT_USER_SUCCESS:
        MerchantUser.upsert(action.payload.data.merchant_user)
        break
      case RESET_DB:
        MerchantUser.all().delete()
        break
      default:
        break
    }
  }

  rolesForMerchantId(merchantId: number) {
    // @ts-ignore
    if (!this.ref.roles || !merchantId) return []
    // @ts-ignore
    return this.ref.roles[merchantId] || []
  }

  canEditAudienceFor(campaign: CampaignFields) {
    const creator = campaign.creator
    const merchantId = campaign.merchant_id
    if (!creator) return true
    const creatorRole = creator.role
    if (!creatorRole) return true
    // @ts-ignore
    if (creator.id !== this.ref.id) {
      const currentMerchantUserRoles = this.rolesForMerchantId(merchantId)
      if (!currentMerchantUserRoles) return false
      if (!currentMerchantUserRoles.includes(creatorRole)) return false
    }
    return true
  }

  isApproverFor(campaign: CampaignFields) {
    if (!campaign.approver) return false
    // @ts-ignore
    return campaign.approver.id === this.ref.id
  }
}
