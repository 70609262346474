import { FormsyCheckbox } from '@thanx/uikit/checkbox'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import checkmark from 'assets/images/checkmark.png'
import feedbackImage from 'assets/images/merchant-users/dashboard-access/feedback.svg'
import oneTimeCampaignImage from 'assets/images/merchant-users/dashboard-access/one-time-campaign.svg'
import InfoIcon from 'components/InfoIcon'
import { buildTranslate } from 'locales'
import { useStyletron } from 'styletron-react'
import { userManagementUrl } from 'utilities/urlUtils'

const t = buildTranslate('merchant_users.form.roles.manager')
const options = ['one_time_campaign', 'reports', 'feedback']
const optionImages = {
  one_time_campaign: oneTimeCampaignImage,
  reports: checkmark,
  feedback: feedbackImage,
}

type Props = {
  visible: boolean
}

const ManagerCardDetails = ({ visible }: Props) => {
  const [css] = useStyletron()

  return (
    <Switch condition={visible}>
      <div
        className={`mb-s d-flex p-s ${css({
          backgroundColor: '#E5F0F8',
        })}`}
      >
        <div className={`mr-s ${css({ width: '24px' })}`}>
          <InfoIcon color="#0073BF" />
        </div>
        <Text.BodyText3 color="grey70">
          {t('helper')}
          <br />
          <a href={userManagementUrl} target="_blank" rel="noopener noreferrer">
            {t('learn_more_text')}
          </a>
        </Text.BodyText3>
      </div>
      {options.map(option => (
        <FormsyCheckbox
          key={option}
          name={option}
          disabled
          checked
          value
          overrides={{
            Root: {
              style: {
                display: 'flex',
                alignItems: 'baseline',
              },
            },
            Checkmark: {
              style: {
                borderRadius: '2px',
                backgroundColor: '#ffffff',
                borderColor: '#ffffff',
                backgroundImage: `url(${optionImages[option]})`,
                backgroundSize: '12px',
              },
            },
          }}
        >
          <div>
            <Text.Header4 color="grey70" className="mb-0">
              {t(`${option}.title`)}
            </Text.Header4>
            <Text.BodyText4 color="grey70">
              {t(`${option}.description`)}
            </Text.BodyText4>
          </div>
        </FormsyCheckbox>
      ))}
    </Switch>
  )
}

export default ManagerCardDetails
