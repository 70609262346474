import React from 'react'
import { Translate } from 'react-redux-i18n'

type ContentWithSaveProps = {
  className?: string
  isSaving: boolean
  value: string
}

const ContentWithSave: React.FC<ContentWithSaveProps> = props => {
  const { isSaving, value, className = '' } = props

  const content = isSaving ? (
    <span>
      <i className="fa fa-spin fa-circle-o-notch mr-2" />
      <Translate
        value="cms.settings.terms_and_policies.nav.saving"
        dangerousHTML
      />
    </span>
  ) : (
    <Translate value={value} dangerousHTML />
  )
  return <span className={`relative ${className}`}>{content}</span>
}

export default ContentWithSave
