import { buildTranslate } from 'locales'
import { useState } from 'react'
interface UseUrlFileDownloadReturn {
  downloadUrlFile: (url: string, filename?: string) => Promise<void>
  isDownloading: boolean
  error: Error | null
}

const tError = buildTranslate('errors')
export const useUrlFileDownload = (): UseUrlFileDownloadReturn => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const downloadUrlFile = async (url: string, filename?: string) => {
    setIsDownloading(true)
    setError(null)

    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error(tError('network_response_error'))
      }

      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl

      // Extract filename from URL if not provided
      const defaultFilename = url.substring(url.lastIndexOf('/') + 1)
      link.download = filename || defaultFilename

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(downloadUrl)
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error(tError('file_download_error'))
      )
    } finally {
      setIsDownloading(false)
    }
  }

  return {
    downloadUrlFile,
    isDownloading,
    error,
  }
}
