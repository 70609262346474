import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { getCampaignTypes } from 'actions/campaignTypes'
import { updateCampaignVariant } from 'actions/campaignVariants'
import { mergeTagsApi } from 'api/mergeTag'
import Formsy from 'formsy-react'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { VariantOrCampaign } from 'models/CampaignVariant'
import { Fields as NotifyEmail } from 'models/NotifyEmail'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'react-router-redux'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import CampaignConfigContext from '../Builder/CampaignConfigContext'
import EmailEditor from '../Builder/components/Forms/EmailEditor'
import ImportModal from '../Builder/Steps/Shared/Messaging/components/ImportModal'
import MergeTagsSetupModal from '../Builder/Steps/Shared/Messaging/components/MergeTagsSetupModal'
import PreviewModal from '../Builder/Steps/Shared/Messaging/components/PreviewModal'
import PreviewNotifyEmailModal from '../Builder/Steps/Shared/Messaging/components/PreviewNotifyEmailModal'
import SendTestModal from '../Builder/Steps/Shared/Messaging/components/SendTestModal'
import EditTopBar from './EditTopBar'
import EmailOptions from './EmailOptions'
import { useEditCampaign } from './hooks'

const t = buildTranslate(`thanx_campaigns.builder.steps.setup.messaging`)

type Props = RouteComponentProps<{
  id: string
  type: string
  variantId: string
}>

const EditEmail: React.FC<Props> = ({ match }) => {
  const [css] = useStyletron()
  const [isValid, setIsValid] = useState<boolean>(true)
  const [isCopying, setIsCopying] = useState(false)
  const [showSendTestModal, setShowSendTestModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [showPreviewNotifyModal, setShowPreviewNotifyModal] = useState(false)
  const [showMergeTagsSetupModal, setShowMergeTagsSetupModal] = useState(false)
  const [selectedNotifyEmail, setSelectedNotifyEmail] =
    useState<NotifyEmail | null>(null)
  const isEmailCopyingEnabled = useFlag('MX4-Copy-An-Email', false)

  const campaignId = Number(match.params.id)
  const variantId = Number(match.params.variantId)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCampaignTypes())
  }, [])

  const { campaign, variant, config, isSaving, debouncedSave, triggerSave } =
    useEditCampaign(campaignId, variantId)

  const {
    data: { merge_tags: mergeTags } = { merge_tags: [] },
    isLoading: isLoadingMergeTags,
    isError: isMergeTagsError,
    isSuccess: isMergeTagsSuccess,
  } = mergeTagsApi.useGetMergeTagsQuery()

  // useEffect(() => {
  //   refetchMergeTags()
  // }, [])

  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  const unlayerEnabled = campaignType?.unlayer_enabled

  const form = useRef<Formsy | null>(null)

  function onContinue() {
    if (!isValid) {
      form?.current?.setFormPristine?.(false)
      return
    }
    if (form.current) {
      triggerSave({
        ...variant,
        ...form.current.getModel(),
      })
    }
    dispatch(push(`/thanx_campaigns/targeted/custom/${campaignId}`))
  }

  async function onRemove() {
    await dispatch(
      updateCampaignVariant(campaignId, variantId, {
        notify_email: false,
      })
    )
    dispatch(push(`/thanx_campaigns/targeted/custom/${campaignId}`))
  }

  async function onFormChange(model: Object) {
    if (!isValid || !form?.current) return
    debouncedSave(model)
  }

  const refresh = () => {
    setIsCopying(true)
    setTimeout(() => {
      setIsCopying(false)
    }, 1000)
  }

  if (!campaign || !variant || !config) return null

  return (
    <div
      className={`${css({
        marginTop: '30px',
        minHeight: `calc(100% - 30px)`,
      })}`}
    >
      <CampaignConfigContext.Provider
        value={{
          config: config as unknown as VariantOrCampaign,
          setConfig: () => {},
        }}
      >
        <EditTopBar
          messageType="email"
          campaign={campaign}
          variant={variant}
          isSaving={isSaving}
          onContinue={onContinue}
          onRemove={onRemove}
        />
        <div
          className={`d-flex align-items-center justify-content-between ${css({
            height: '64px',
            borderBottom: '1px solid #ddd',
            paddingLeft: '24px',
            paddingRight: '24px',
          })}`}
        >
          <Text.Header4 className={css({ lineHeight: '2' })}>
            {t('email.title')}
          </Text.Header4>
          <EmailOptions
            campaign={campaign}
            unlayerEnabled={!!unlayerEnabled}
            config={config}
            isEmailCopyingEnabled={isEmailCopyingEnabled}
            setShowPreviewModal={setShowPreviewModal}
            setShowImportModal={setShowImportModal}
            setShowSendTestModal={setShowSendTestModal}
          />
        </div>
        <Formsy
          onChange={onFormChange}
          ref={form}
          onValid={() => setIsValid(true)}
          onInvalid={() => setIsValid(false)}
        >
          <div className="px-xl">
            <EmailEditor
              campaign={campaign}
              isReloading={isCopying}
              mergeTags={mergeTags}
              setShowMergeTagsSetupModal={setShowMergeTagsSetupModal}
            />
          </div>
        </Formsy>
        <SendTestModal
          campaign={campaign}
          show={showSendTestModal}
          onModalClose={() => setShowSendTestModal(false)}
        />
        <MergeTagsSetupModal
          mergeTags={mergeTags}
          show={showMergeTagsSetupModal}
          isLoading={isLoadingMergeTags}
          isError={isMergeTagsError}
          isSuccess={isMergeTagsSuccess}
          onModalClose={() => {
            setShowMergeTagsSetupModal(false)
          }}
        />
        <PreviewModal
          campaign={campaign}
          show={showPreviewModal}
          onModalClose={() => setShowPreviewModal(false)}
        />
        <ImportModal
          config={config}
          campaign={campaign}
          show={showImportModal}
          onSelectPreview={(notifyEmail: NotifyEmail) => {
            setSelectedNotifyEmail(notifyEmail)
            setShowImportModal(false)
            setShowPreviewNotifyModal(true)
          }}
          onModalClose={() => {
            setShowImportModal(false)
            refresh()
          }}
        />
        <PreviewNotifyEmailModal
          show={showPreviewNotifyModal}
          campaign={campaign}
          config={config}
          notifyEmail={selectedNotifyEmail}
          onBack={() => {
            setShowPreviewNotifyModal(false)
            setShowImportModal(true)
          }}
          onClose={() => {
            setShowPreviewNotifyModal(false)
            refresh()
          }}
        />
      </CampaignConfigContext.Provider>
    </div>
  )
}

export default EditEmail
