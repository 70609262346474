import { Text } from '@thanx/uikit/text'
import React from 'react'

import { termsOfServiceApi } from 'api/termsOfService'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import Helmet from 'react-helmet'

const getTranslations = () => {
  const t = buildTranslate('privacy.privacy')
  const titles = buildTranslate('titles')
  return { t, titles }
}

type Props = {
  handle: string
  testId?: string
}

const ThanxPrivacy = ({ handle, testId }: Props) => {
  const { t, titles } = getTranslations()

  const { data, isLoading } =
    termsOfServiceApi.useGetTermsOfServiceQuery(handle)
  const { merchant_name } = data ?? {}

  return (
    <div className="p-xl pt-xl" data-testid={testId}>
      {isLoading && (
        <Spinner className="mt-xl text-center" isLoading size="2x" />
      )}
      {!isLoading && merchant_name && (
        <div>
          <Helmet>
            <title>{titles('privacy_branded', { name: merchant_name })}</title>
          </Helmet>
          <Text.Header2>
            {t('merchant', {
              name: merchant_name,
            })}
          </Text.Header2>
        </div>
      )}
      <Text.Header2>{t('title')}</Text.Header2>
      <Text.BodyText2 className="italic">{t('effective_date')}</Text.BodyText2>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('see_previous'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('policy.header'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <ol>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('policy.one'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('policy.two'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('policy.three'),
              }}
            />
          </Text.BodyText3>
        </li>
      </ol>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('policy.services'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <Text.Header4>{t('revisions.title')}</Text.Header4>
      <Text.BodyText3>{t('revisions.revisions')}</Text.BodyText3>
      <Text.Header4>{t('personal_information.title')}</Text.Header4>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('personal_information.includes'),
          }}
        />
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.contact_data'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.profile_data'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.payment_data'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.order_data'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.image_data'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.communications_data'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.account_preferences_data'),
              }}
            />
          </Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('personal_information.third_party_sources'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.transactional_data'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.merchant_data'),
              }}
            />
          </Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('personal_information.automatically_collected'),
          }}
        />
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.device_data'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.online_activity_data'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.geolocation_data'),
              }}
            />
          </Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('personal_information.cookies.title'),
          }}
        />
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.cookies.cookies_explanation'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.cookies.web_beacons'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.cookies.sdk'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'personal_information.cookies.session_replay_technologies'
                ),
              }}
            />
          </Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>
        {t('personal_information.some_of_these_cookies')}
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.advertising'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information.analytics'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('personal_information.essential')}</Text.BodyText3>
        </li>
      </ul>

      <Text.BodyText3>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('personal_information.learn_more_cookies'),
            }}
          />
        </Text.BodyText3>
      </Text.BodyText3>
      <Text.Header4>{t('personal_information_use.title')}</Text.Header4>
      <Text.BodyText3>{t('personal_information_use.pii_use')}</Text.BodyText3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('personal_information.service_delivery.header'),
          }}
        />
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.service_delivery.provide')}
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.service_delivery.enable')}
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.service_delivery.communicate')}
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.service_delivery.send')}
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.service_delivery.understand')}
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.service_delivery.support')}
          </Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('personal_information_use.research_and_development'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'personal_information_use.marketing_and_advertising.header'
            ),
          }}
        />
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'personal_information_use.marketing_and_advertising.direct'
                ),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'personal_information_use.marketing_and_advertising.interest'
                ),
              }}
            />
          </Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'personal_information_use.compliance_and_protection.header'
            ),
          }}
        />
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.compliance_and_protection.comply')}
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.compliance_and_protection.protect')}
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.compliance_and_protection.audit')}
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.compliance_and_protection.enforce')}
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            {t('personal_information_use.compliance_and_protection.prevent')}
          </Text.BodyText3>
        </li>
      </ul>
      <Text.Header4>
        {t('personal_information_use.information_sharing.title')}
      </Text.Header4>
      <Text.BodyText3>
        {t('personal_information_use.information_sharing.header')}
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'personal_information_use.information_sharing.thanx_service_merchants_one'
            ),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <Text.BodyText3>
        {t(
          'personal_information_use.information_sharing.thanx_service_merchants_two'
        )}
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'personal_information_use.information_sharing.merchant_branded_services'
            ),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'personal_information_use.information_sharing.services_providers'
            ),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'personal_information_use.information_sharing.compliance'
            ),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'personal_information_use.information_sharing.business_transactions'
            ),
          }}
        />
      </Text.BodyText3>
      <Text.Header4>
        {t('personal_information_use.your_choices.title')}
      </Text.Header4>
      <Text.BodyText3>
        {t('personal_information_use.your_choices.header')}
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('personal_information_use.your_choices.opt_out'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'personal_information_use.your_choices.changing_information'
                ),
              }}
            />
          </Text.BodyText3>
        </li>
      </ul>
      <Text.Header4>{t('california_incentives.title')}</Text.Header4>
      <Text.BodyText3>{t('california_incentives.enables')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('california_incentives.when_you')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('california_incentives.if_you_wish'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <Text.BodyText3>
        {t('california_incentives.rewards_available')}
      </Text.BodyText3>
      <Text.Header4>{t('california_incentives.security.title')}</Text.Header4>
      <Text.BodyText3>
        {t('california_incentives.security.employ')}
      </Text.BodyText3>
      <Text.Header4>
        {t('california_incentives.international_transfer.title')}
      </Text.Header4>
      <Text.BodyText3>
        {t('california_incentives.international_transfer.transfer')}
      </Text.BodyText3>
      <Text.Header4>
        {t('california_incentives.california_privacy_rights.title')}
      </Text.Header4>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              'california_incentives.california_privacy_rights.for_info'
            ),
          }}
        />
      </Text.BodyText3>
      <Text.Header4>
        {t('california_incentives.links_to_other_sites.title')}
      </Text.Header4>
      <Text.BodyText3>
        {t('california_incentives.links_to_other_sites.the_services')}
      </Text.BodyText3>
      <Text.Header4>{t('california_incentives.children.title')}</Text.Header4>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('california_incentives.children.not_directed'),
          }}
        />
      </Text.BodyText3>
      <Text.Header4>
        {t('california_incentives.do_not_track.title')}
      </Text.Header4>
      <Text.BodyText3>
        {t('california_incentives.do_not_track.feature')}
      </Text.BodyText3>
      <Text.Header4>
        {t('california_incentives.contacting_us.title')}
      </Text.Header4>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('california_incentives.contacting_us.questions'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <Text.BodyText3 bold>{t('last_updated')}</Text.BodyText3>
      <br></br>
      <Text.BodyText3>{`© ${t('footer', {
        year: new Date().getFullYear(),
      })}`}</Text.BodyText3>
    </div>
  )
}

export default ThanxPrivacy
