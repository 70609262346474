import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { Role } from 'api/merchantUsers'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import CustomerServiceCardDetails from './CustomerServiceCardDetails'
import { roleTitle } from './helpers'
import ManagerCardDetails from './ManagerCardDetails'
import RadioButton from './RadioButton'

const t = buildTranslate('merchant_users.form.roles')

type Props = {
  role: Role
  hideHeader?: boolean
  onChange?: (role: Role) => void
}

const RolesCard = ({ role = Role.admin, onChange }: Props) => {
  const merchant = useCurrentMerchant()
  const isMall = merchant?.merchant_type === 'Mall'

  const Details = ({ role }: { role: Role }) => {
    const scope =
      role === Role.customer_service_read ||
      role === Role.customer_service_write
        ? 'customer_service'
        : role

    return (
      <>
        <Text.Header4 bold className="mt-xxs">
          {roleTitle(role, isMall)}
        </Text.Header4>
        <Text.BodyText4 color="grey70" tag="p">
          {t(`${scope}.description`)}
        </Text.BodyText4>
      </>
    )
  }

  const isSelected = (checkRole: Role | Role[]) => {
    return Array.isArray(checkRole)
      ? checkRole.includes(role)
      : role === checkRole
  }
  const [css] = useStyletron()

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
      })}
    >
      <RadioButton
        key={Role.admin}
        checked={isSelected(Role.admin)}
        value={Role.admin}
        onChange={value => {
          onChange?.(value as Role)
        }}
      >
        <Details role={Role.admin} />
      </RadioButton>

      <RadioButton
        key={Role.manager}
        checked={isSelected(Role.manager)}
        value={Role.manager}
        onChange={value => {
          onChange?.(value as Role)
        }}
      >
        <Details role={Role.manager} />
        <ManagerCardDetails visible={isSelected(Role.manager) && !isMall} />
      </RadioButton>

      <RadioButton
        key={'customer_service'}
        checked={isSelected([
          Role.customer_service_read,
          Role.customer_service_write,
        ])}
        value={'customer_service'}
        onChange={() => {
          if (
            !isSelected([
              Role.customer_service_read,
              Role.customer_service_write,
            ])
          ) {
            onChange?.(Role.customer_service_write)
          }
        }}
      >
        <Details role={Role.customer_service_read} />
        <CustomerServiceCardDetails
          role={role}
          visible={isSelected([
            Role.customer_service_write,
            Role.customer_service_read,
          ])}
          onChange={value => {
            onChange?.(value as Role)
          }}
        />
      </RadioButton>

      <RadioButton
        key={Role.accountant}
        checked={isSelected(Role.accountant)}
        value={Role.accountant}
        onChange={value => {
          onChange?.(value as Role)
        }}
      >
        <Details role={Role.accountant} />
      </RadioButton>
    </div>
  )
}

export default RolesCard
