import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { mergeTagsApi, type MergeTag } from 'api/mergeTag'
import CircleExclamation from 'assets/icons/circle_exclamation.svg'
import Modal from 'components/ModalContainer'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { getCurrentMergeTagsMap } from 'utilities/mergeTags'
import { MergeTagItem } from 'utilities/mergeTags'
import MergeTagTable from './MergeTagTable'

type MergeTagsSetupModalProps = {
  mergeTags: MergeTag[]
  show: boolean
  onModalClose: () => void
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}

const t = buildTranslate(
  'thanx_campaigns.builder.forms.messaging_tabs.merge_tags.modal'
)
const tError = buildTranslate('errors')

const MergeTagsSetupModal: React.FC<MergeTagsSetupModalProps> = props => {
  const { mergeTags, show, onModalClose, isLoading, isError, isSuccess } = props

  const [currentMergeTagsMap, setCurrentMergeTagsMap] = useState<
    Map<number, MergeTagItem>
  >(getCurrentMergeTagsMap(new Map(), mergeTags))

  useEffect(() => {
    setCurrentMergeTagsMap(getCurrentMergeTagsMap(new Map(), mergeTags))
  }, [mergeTags])

  const mergeTagsArray = Array.from(currentMergeTagsMap.values())

  const [updateMergeTags, { isLoading: isUpdatingMergeTags }] =
    mergeTagsApi.useUpdateMergeTagsMutation()

  const onProceed = () => {
    const mergeTagsPayload = mergeTagsArray.map(mergeTag => ({
      id: mergeTag.id,
      default_value: mergeTag.defaultValue,
    }))
    updateMergeTags({ merge_tag_defaults: mergeTagsPayload })
  }

  const onInputChange = (id: number, newValue: string) => {
    const currentMergeTagItem = currentMergeTagsMap.get(id)
    if (currentMergeTagItem) {
      currentMergeTagItem.defaultValue = newValue
      currentMergeTagsMap.set(id, currentMergeTagItem)
      setCurrentMergeTagsMap(new Map(currentMergeTagsMap))
    }
  }

  const onModalCloseHandler = () => {
    onModalClose()
    setCurrentMergeTagsMap(getCurrentMergeTagsMap(new Map(), mergeTags))
  }

  const loadingContent = (
    <Spinner
      isLoading={isLoading}
      data-testid="merge-tags-setup-modal-spinner"
      size="2x"
      className="d-flex justify-content-center pt-xl"
    />
  )
  const errorContent = (
    <div
      data-testid="merge-tags-setup-modal-error"
      className="d-flex flex-column align-items-center justify-content-center mt-m"
    >
      <div>
        <img src={CircleExclamation} alt="lock" width="32" height="32" />
      </div>
      <Text.Header4 bold className="mt-m mb-xs grey-90">
        {tError('network_response_error')}
      </Text.Header4>
    </div>
  )

  let fallbackContent: React.ReactNode | null = null
  if (isLoading) {
    fallbackContent = loadingContent
  }
  if (isError) {
    fallbackContent = errorContent
  }

  return (
    <Modal
      title={t('title')}
      subtitle={t('subtitle')}
      proceedText={t('save_button')}
      onProceed={onProceed}
      isOpen={show}
      onClose={onModalCloseHandler}
      isLoading={isUpdatingMergeTags}
      // showBottomProceed={false}
      // hideCancel={true}
      zIndex={1001}
      width="650px"
    >
      <Switch condition={isSuccess} fallback={fallbackContent}>
        <MergeTagTable
          mergeTagsArray={mergeTagsArray}
          onInputChange={onInputChange}
        />
      </Switch>
    </Modal>
  )
}

export default MergeTagsSetupModal
