import { api } from './index'

export enum LegalDocTypeEnum {
  PRIVACY_POLICY = 'privacy_policy',
  TERMS_OF_SERVICE = 'terms_and_conditions',
  CCPA = 'ccpa',
}

export type LegalDocType =
  typeof LegalDocTypeEnum[keyof typeof LegalDocTypeEnum]

export type LegalDoc = {
  id: number
  legal_doc_type: LegalDocType
  state: 'draft' | 'published'
  url: string | null
  thanx_hosted: boolean
  updated_at: string
  html: string | null
  configuration: any | null
}

type GetLegalDocsResponse = { legal_docs: LegalDoc[] }

type GetLegalDocResponse = { legal_doc: LegalDoc }

export type CreateLegalDocPayload = {
  legal_doc_type: LegalDocType
  html?: string
  configuration?: any
  thanx_hosted?: boolean
  url?: string
  state?: 'draft' | 'published'
}

export type PublishLegalDocPayload = {} & { id: number }

export type UpdateLegalDocPayload = Partial<
  Pick<LegalDoc, 'configuration' | 'state' | 'thanx_hosted' | 'url'>
> & { id: number }

export type PreviewLegalDocPayload = {
  configuration: any
}

export type PreviewLegalDocResponse = {
  html: string
}

export type GetPublicPolicyPayload = {
  legal_doc_type: LegalDocType
  merchant_handle: string
}

export const legalDocTags = ['LegalDocs']

export const legalDocsApi = api.injectEndpoints({
  endpoints: builder => ({
    getLegalDocs: builder.query<GetLegalDocsResponse, void>({
      query: () => {
        return {
          url: 'legal_doc',
        }
      },
      providesTags: ['LegalDoc'],
    }),

    getLegalDoc: builder.query<GetLegalDocResponse, string>({
      query: id => ({ url: `legal_doc/${id}` }),
      providesTags: ['LegalDoc'],
    }),

    createLegalDoc: builder.mutation<
      GetLegalDocResponse,
      CreateLegalDocPayload
    >({
      query: payload => ({
        url: 'legal_doc',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['LegalDoc'],
    }),
    publishLegalDoc: builder.mutation<LegalDoc, PublishLegalDocPayload>({
      query: payload => {
        const { id, ...rest } = payload
        return {
          url: `legal_doc/${id}/publish`,
          method: 'POST',
          body: rest,
        }
      },
      invalidatesTags: ['LegalDoc'],
    }),

    updateLegalDoc: builder.mutation<
      GetLegalDocResponse,
      UpdateLegalDocPayload
    >({
      query: patch => {
        const { id, ...rest } = patch
        return {
          url: `legal_doc/${id}`,
          method: 'PATCH',
          body: rest,
        }
      },
      invalidatesTags: ['LegalDoc'],
    }),

    deleteLegalDoc: builder.mutation<void, number>({
      query: id => ({
        url: `legal_doc/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LegalDoc'],
    }),

    previewLegalDoc: builder.mutation<
      PreviewLegalDocResponse,
      PreviewLegalDocPayload
    >({
      query: payload => ({
        url: 'legal_doc/preview',
        method: 'POST',
        body: payload,
      }),
    }),

    getPublicPolicy: builder.query<GetLegalDocResponse, GetPublicPolicyPayload>(
      {
        query: payload => {
          const { legal_doc_type, merchant_handle } = payload
          return {
            url: `legal/doc`,
            method: 'GET',
            params: {
              legal_doc_type,
              merchant_handle,
            },
          }
        },
      }
    ),
  }),
})
