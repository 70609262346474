import confirmModal from './confirmModal'
import incentive from './incentive'
import variants from './variants'

export default {
  page_title: 'Edit campaign draft | Thanx',
  errors: {
    load_campaign: 'We had trouble loading your campaign. Try again?',
    load_campaign_type: 'We had trouble configuring your campaign. Try again?',
    reload_button: 'Click to try again',
    save_campaign: 'We had trouble saving your campaign.',
  },
  exit_modal: {
    title: 'Are you sure you want to leave?',
    body: 'You haven’t finished your campaign yet. If you leave now we’ll save it as a draft so you can finish it later.',
    buttons: {
      exit: 'Save and Exit',
      finish: 'Finish Campaign',
    },
  },
  forms: {
    audience: {
      get_metrics_error:
        'There was an error fetching your audience, please try again.',
      validation_error:
        '<i class="fa fa-lg fa-exclamation-circle" aria-hidden="true"></i><span class="margin-left-medium">Please select at least one group of customers to target</span>',
      unit: 'Customer',
      members: {
        title: 'Loyalty members',
        subtitle: 'Customers who have joined your loyalty program.',
      },
      subscribers: {
        title: 'Email subscribers',
        list_present: {
          subtitle:
            'Customers who have joined your mailing list but have not joined your loyalty program.',
          link: 'Add more',
        },
        no_list_present: {
          subtitle: 'to increase your reach.',
          link: 'Import your email list',
        },
      },
      sum: {
        title: 'Total Reach',
      },
      refinements: {
        title: 'Audience Refinements',
      },
      locationRefinement: {
        location: {
          label: 'Filter by location:',
          placeholder: '%{count} location',
          placeholder_plural: '%{count} locations',
          expand: 'Show %{count} more location',
          expand_plural: 'Show %{count} more locations',
        },
        category: {
          label: 'Filter by category:',
          placeholder: '%{count} category',
          placeholder_plural: '%{count} categories',
          expand: 'Show %{count} more category',
          expand_plural: 'Show %{count} more categories',
        },
      },
    },
    messaging_tabs: {
      page_title: 'Edit campaign message | Thanx',
      email: 'Email',
      push: 'Push',
      sms: 'SMS',
      fb: 'Facebook',
      disabled: {
        push: 'This channel is only available when you include an offer.',
        sms: 'This channel is only available when you include an offer.',
        fb: 'Coming soon!',
        all: 'There’s something wrong in this form. Check the error messages below.',
      },
      import: {
        title: 'Import content',
        title_v2: 'Copy from another email',
        modal: {
          from: 'Import from variant %{name}',
          from_v2: 'Copy from variant %{name}',
          title: 'Import message from a variant in this campaign',
          title_v2: 'Copy content from another email',
          subtitle:
            'Any content currently in your draft message will be replaced.',
          proceed: 'Import message',
          proceed_v2: 'Copy',
          footer:
            'This will only import the email message. Rewards, restrictions, fine print, Push and SMS notifications will not be imported.',
          campaign_group_options: {
            variant: 'Copy from a variant in this campaign',
            active: 'Copy from another campaign — Active',
            complete: 'Copy from another campaign — Complete',
            draft: 'Copy from another campaign — Drafts',
            scheduled: 'Copy from another campaign — Scheduled',
            in_review: 'Copy from another campaign — Need review',
          },
          email_list: {
            preview: 'Preview',
            copy: 'Copy',
            no_campaigns_available: 'No campaigns available',
          },
        },
        not_ab_tooltip:
          'You can only import content from another variant when creating an A/B test.',
        tooltip: 'Import content from another variant in this A/B test.',
      },
      merge_tags: {
        btn_title: 'Setup merge tags',
        modal: {
          title: 'Merge tags',
          subtitle: `Add a default merge tag value. This text will appear if a customer's data isn't available to display. For example, they haven't added their last name.`,
          edit_button: 'Edit',
          save_button: 'Save',
        },
      },
      send_test: {
        title: 'Send a test',
        description:
          'Enter the email addresses to which you would like to send a test.',
        email_help: 'Separate multiple email addresses with commas or spaces',
        email_label: 'Email addresses',
        variant: {
          title: 'Receive this email as',
          member: 'Loyalty member',
          subscriber: 'Email subscriber',
        },
        submit_button: 'Send test',
      },
      preview: {
        title: 'Preview',
        tabs: {
          member: {
            tab_title: 'Loyalty Members',
            radio_title: 'Preview this email as a loyalty member',
            description: 'You are previewing this email as a loyalty member',
          },
          subscriber: {
            tab_title: 'Email Subscribers',
            radio_title: 'Preview this email as an email subscriber',
            description: 'You are previewing this email as an email subscriber',
          },
        },
      },
      preview_notify_email: {
        title: 'Copy content from this email',
        copy: 'Copy',
      },
    },
    sms: {
      text: 'SMS Message',
      help_text:
        'Enter a custom message to your customers. If you leave this empty, we’ll send the default message displayed.',
    },
    push: {
      text: 'Push Message',
      help_text:
        'Enter a custom message to your customers. If you leave this empty, we’ll send the default message displayed.',
    },
    email: {
      subject: 'Subject Line',
      preview_text: 'Preview Text',
      hero_image: {
        title: 'Hero Image',
      },
      logo_image: {
        title: 'Logo',
      },
      body: {
        text: 'Body',
        help_text:
          'If you don’t want a body in your email, you will need to disable the section.',
      },
      title: {
        text: 'Title',
        help_text:
          'If you don’t want a title in your email, you will need to disable the section.',
      },
      button: {
        title: 'Add or edit a call to action',
        text: 'Button Text',
        bkg_color: 'Button Color',
        text_color: 'Text Color',
        url: 'Link Address (URL)',
        reputation_message:
          'This button will take users to review the location they rated on Google Places.',
      },
      image_control: {
        upload: 'Upload',
        link: 'Add a Link',
        modal: {
          title: 'Add a Link',
          primary_btn: 'Done',
          clear_btn: 'Clear',
        },
        image_link: 'When clicked, this image will take the customer to &nbsp;',
      },
      incentive: 'Your Offer',
      signup: 'Loyalty Signup Promo',
      signup_message:
        'This will only be shown to customers who aren’t yet loyalty members',
      redeem_control: {
        box_bkg: 'Box Color',
        box_text: 'Box Text Color',
        button_bkg: 'Button Color',
        button_text: 'Button Text Color',
        description: 'Reward',
      },
    },
  },
  previews: {
    email: {
      default_small_text:
        'Simply create your account and register a credit or debit card and whenever you visit you’ll automatically earn credit towards rewards. There are no cards to carry and no check-ins required.',
      button_incentive: 'Click to redeem',
      button_intro: 'Join and redeem',
      subheader_intro: 'When you join our loyalty program',
      join_header: 'Get rewarded for being a loyal customer',
      join_body:
        'Join our loyalty program and get %{redeemDescription} on day one! You’ll also earn progress towards exclusive rewards every time you shop.',
    },
    sms: {
      default:
        '🤖 Yay! You have a new reward from %{merchant}! I don’t wanna spoil the surprise. Click this to see: <span class="fake-link">http://bit.ly/2qTbgCI</span>',
      custom:
        '🤖 %{merchant} says "%{customText}" Check it out! <span class="fake-link">http://bit.ly/2qTbgCI</span>',
    },
    push: {
      custom: '%{merchant} says "%{customText}"',
    },
    incentive: {
      tiny_header: 'Special offer',
      redeem_btn: 'REDEEM',
      nav: {
        rewards: 'REWARDS',
        locations: 'LOCATIONS',
        more: 'MORE',
      },
      no_redeem: {
        title: 'Unlock even more channels by including an incentive',
        subtitle:
          'Some channels like SMS can only be used when you include an offer.',
      },
    },
  },
  nav: {
    message: {
      title: 'Send a message to everyone',
    },
    'timeshift-morning': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Drive traffic to the morning',
    },
    'timeshift-midday': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Drive traffic to midday',
    },
    'timeshift-afternoon': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Drive traffic to the afternoon',
    },
    'timeshift-evening': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Drive traffic to the evening',
    },
    'timeshift-night': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Drive traffic to nighttime',
    },
    'timeshift-weekend': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Drive traffic to the weekend',
    },
    'timeshift-weekday': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Drive traffic to weekdays',
    },
    reputation: {
      title:
        '<span class="hidden-xs hidden-sm">Automated Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Reputation Manager',
    },
    winback: {
      title:
        '<span class="hidden-xs hidden-sm">Automated Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Winback',
    },
    upsell: {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Get customers to try something new',
    },
    'location-message': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Engage customers based on location',
    },
    'location-close': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Close a location',
    },
    'location-promote': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Cross-promote a location',
    },
    'location-reopen': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Reopen a location',
    },
    'engage-vip': {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Surprise and delight my VIPs',
    },
    custom: {
      title:
        '<span class="hidden-xs hidden-sm">Targeted Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Create a custom campaign',
    },
    v3: {
      title:
        '<span class="hidden-xs hidden-sm">Automated Campaigns <i class="fa fa-chevron-right font-size-18 inline-block margin-left-small margin-right-small"></i></span> Create a custom campaign',
      steps: {
        name: 'Campaign Name',
        variantSelect: 'Test',
        review: 'Configuration',
      },
      save_button: 'Back to your campaign',
    },
    variant: 'Variant %{name}',
    config: 'Configuration',
  },
  info_modal: {
    button: {
      create_initial: 'Get Started',
      continue: 'Continue',
      draft: 'Continue previous draft',
      create_new: 'Create a new campaign',
    },
    message: {
      title: 'Engage with all of your customers',
      subtitle:
        'Send a message to all your customers, with or without an offer',
      item_1: {
        title: 'Create a beautiful message in minutes.',
        subtitle:
          'With pre-built templates, create messages that look and feel like your brand.',
      },
      item_2: {
        title: 'Flexible rewards.',
        subtitle:
          'Choose when to include a reward and offer it to customers even if they aren’t in your loyalty program.',
      },
      item_3: {
        title: 'Measure the true impact of your campaign.',
        subtitle:
          'Go beyond opens and clicks to see how much revenue your campaign generated.',
      },
    },
    daypart: {
      title: 'Drive traffic during a specific time of day',
      subtitle: 'Grow revenue by increasing frequency',
      item_1: {
        title: 'Choose a time to promote.',
        subtitle:
          'Encourage customers to visit your business a time or day they otherwise wouldn’t.',
      },
      item_2: {
        title: 'Incentivize customers to visit.',
        subtitle:
          'Only target customers who don’t already visit during this time or day.',
      },
      item_3: {
        title: 'Measure the impact.',
        subtitle:
          'Go beyond opens and clicks to see how many customers increased their visit frequency and how much revenue it generated.',
      },
    },
    weekpart: {
      title: 'Drive traffic during a specific day of the week',
      subtitle: 'Grow revenue by increasing frequency',
      item_1: {
        title: 'Choose what part of the week to promote.',
        subtitle:
          'Encourage customers to visit your business during part of the week they otherwise wouldn’t.',
      },
      item_2: {
        title: 'Incentivize customers to come in.',
        subtitle:
          'Only target customers who don’t already visit during this part of the week.',
      },
      item_3: {
        title: 'Measure the impact.',
        subtitle:
          'Go beyond opens and clicks to see how many customers increased their visit frequency and how much revenue it generated.',
      },
    },
    reputation: {
      title: 'Turn positive feedback into stars',
      subtitle: 'Link happy customers to your business pages',
      item_1: {
        title: 'Customers already give you positive feedback',
        subtitle:
          'Thanx gathers positive feedback after customers make purchases every day.',
      },
      item_2: {
        title: 'Invite those customers to rate you online',
        subtitle:
          'Automatically send customers an invitation to share their opinion online (Google) – only asks once a year per customer.',
      },
      item_3: {
        title: 'Measure how many customers click to go do it',
        subtitle:
          'Every rating counts when your reputation is at stake. You’ll see how many customers click to rate the relevant business page for where they made their purchase.',
      },
    },
    winback: {
      title: 'Win back your customers',
      subtitle: 'Re-engage customers the moment they stop coming in',
      item_1: {
        title: 'Thanx automatically finds your at-risk customers',
        subtitle:
          'By monitoring each customer’s normal behavior, Thanx detects when they significantly deviate from that norm.',
      },
      item_2: {
        title: 'Create a reward that can win them back',
        subtitle:
          'You create a message and a reward that can turn them around. Thanx recommends something worth close to your average check.',
      },
      item_3: {
        title: 'Measure how many customers you win back and what they’re worth',
        subtitle:
          'When you win back a customer you’re winning back all their future spending.',
      },
    },
    upsell: {
      title: 'Get customers to try something new',
      subtitle: 'Increase profits by getting customers addicted to new items.',
      item_1: {
        title: 'Choose an item to promote',
        subtitle:
          'Encourage customers to purchase a more expensive item or add-on to increase your average check.',
      },
      item_2: {
        title: 'Incentivize customers to try it',
        subtitle:
          'Only target customers who don’t already buy it and give them an exclusive offer.',
      },
      item_3: {
        title: 'Measure the impact',
        subtitle:
          'Go beyond opens and clicks to see how many customers tried your item and how much revenue it generated.',
      },
    },
    upsell_restricted: {
      title: 'Supercharge your campaigns',
      subtitle:
        'Create highly personalized campaigns – powered by Thanx POS Sync',
      item_1: {
        title:
          'A deeper understanding of your customers means even more effective campaigns',
        subtitle:
          'Understand the purchasing behavior of each individual customer to target them with a relevant message based on their unique preferences.',
      },
      item_2: {
        title: 'Uncover actionable opportunities within your data',
        subtitle:
          'Explore your POS data and find opportunities to promote underperforming items, announce new ones and much more!',
      },
      item_3: {
        title: 'Go beyond opens and clicks',
        subtitle:
          'See what items customers purchase after you send them a campaign measure the how much your message changed their behavior.',
      },
      button: {
        title: 'Contact Us',
      },
    },
    location_message: {
      title: 'Engage customers based on location',
      subtitle: 'Tell customers what’s going on at a specific location',
      item_1: {
        title: 'Choose a location',
        subtitle:
          'Select a location and only target the customers who actively shop there.',
      },
      item_2: {
        title: 'Tell customers what’s going on there',
        subtitle:
          'Let these people know what new and exciting things are happening at this location.',
      },
      item_3: {
        title: 'Measure how much traffic you drive',
        subtitle:
          'Go beyond opens and clicks to see how many customers came back into your store and the revenue it generated.',
      },
    },
    location_close: {
      title: 'Tell customers that a location is closing down',
      subtitle: 'Give customers a heads up to avoid negative responses',
      item_1: {
        title: 'Choose a location',
        subtitle:
          'Select which location you are closing and target all of the customers who actively shop there.',
      },
      item_2: {
        title: 'Promote nearby locations',
        subtitle:
          'Just because your location is closing doesn’t mean you need to lose these customers. Try incentivizing them to visit other nearby stores.',
      },
      item_3: {
        title: 'See how many customers come back',
        subtitle:
          'Go beyond opens and clicks to see how many customers continued to visit your other stores. ',
      },
    },
    location_reopen: {
      title: 'Tell customers that your location is reopening',
      subtitle: 'Get customers back in the door',
      item_1: {
        title: 'Choose a location',
        subtitle:
          'Select which location you are reopening and engage customers that liked to shop there.',
      },
      item_2: {
        title: 'Announce the reopening',
        subtitle:
          'Let customers know that you’re back in business. Even better, get them to come back and celebrate the grand reopening with you.',
      },
      item_3: {
        title: 'See customers come back',
        subtitle:
          'Go beyond opens and clicks to see how many customers came back into the store and the revenue generated.',
      },
    },
    location_promote: {
      title: 'Attract more customers by cross promoting stores',
      subtitle: 'Market to customers at nearby locations to boost traffic',
      item_1: {
        title: 'Choose a location to promote',
        subtitle:
          'Find a location that you want to boost traffic for and engage with customers that shop at nearby stores.',
      },
      item_2: {
        title: 'Cross-promote to drive sales',
        subtitle:
          'Incentivize customers to visit another store near where they already shop to get them engaging with more of your locations.',
      },
      item_3: {
        title: 'See how many customers purchase',
        subtitle:
          'Go beyond opens and clicks to see how much traffic you drive to a specific location and the revenue it creates.',
      },
    },
    engage_vip: {
      title: 'Show your VIPs how much you appreciate them',
      subtitle: 'Surprise your VIPs with an amazing gift',
      item_1: {
        title: 'Identify your best customers',
        subtitle:
          'Your VIPs are the people who don’t just spend big but do so every month. These are %{merchant}’s die-hard fans.',
      },
      item_2: {
        title: 'Show them some love',
        subtitle:
          'What better way to show these customers how much you appreciate their ongoing support then by surprising them with something delightful?',
      },
      item_3: {
        title: 'Increase their brand loyalty',
        subtitle:
          'A seemingly small act can go a long in way in creating brand affinity and building long-lasting loyalty.',
      },
    },
  },
  steps: {
    name: {
      default: 'Automated Campaign',
      title: 'Campaign name',
      content:
        'How this campaign will appear in your dashboard. Customers won’t see this.',
    },
    nav: {
      buttons: {
        exit: 'Save & Exit',
        continue:
          'Continue <i class="fa fa-arrow-right margin-left-small"></i>',
        send_now:
          'Send Campaign <i class="fa fa-arrow-right margin-left-small"></i>',
        schedule:
          'Schedule Campaign <i class="fa fa-arrow-right margin-left-small"></i>',
        review: 'Continue <i class="fa fa-arrow-right margin-left-small"></i>',
        saving: '<i class="fa fa-spin fa-circle-o-notch"></i> Saving...',
        request_review:
          'Submit For Review <i class="fa fa-arrow-right margin-left-small"></i>',
        approve:
          'Approve Campaign <i class="fa fa-arrow-right margin-left-small"></i>',
        automated:
          'Start Campaign <i class="fa fa-arrow-right margin-left-small"></i>',
        custom_automated_review:
          'Submit For Review <i class="fa fa-arrow-right margin-left-small"></i>',
        custom_automated:
          'Start Campaign <i class="fa fa-arrow-right margin-left-small"></i>',
      },
    },
    success: {
      title: 'You’re all done',
      schedule: {
        content:
          'Your campaign has been scheduled for <strong class="grey-90">%{sendAt}</strong>.',
      },
      send_now: {
        content:
          'Your campaign is in our queue and will be sent to your customers shortly.',
      },
      request_review: {
        title: 'This campaign is in review',
        content:
          '%{approver} needs to review and approve this campaign before it can be sent on <strong>%{sendAtWithTime}</strong>',
      },
      automated: {
        content: 'Your campaign is now active.',
      },
      credit_review: {
        content:
          'Automatic statement credit campaigns may be subject to review by your Thanx success team.',
      },
      buttons: {
        preview: 'Preview Report',
        campaign: 'Back To Campaigns',
      },
    },
    setup: {
      audience: {
        title: 'Choose Your Audience',
        subtitle: 'Who do you want to reach?',
      },
      messaging: {
        title: 'Compose Your Message',
        subtitle: 'Give your message a branded look and feel.',
        email: {
          title: 'Email',
          customize: 'Edit',
          add: 'Add email',
          edit: 'Edit email',
          send_test: 'Send a test',
          missing: 'No email',
          remove: 'Remove email message',
          remove_modal: {
            title: 'Remove this email message',
            description: "You will lose any content you've created",
            cta: 'Remove email message',
          },
        },
        push: {
          title: 'Push message',
          add: 'Add push',
          edit: 'Edit push message',
          missing: 'No push',
          missing_reward: 'No push (include a reward to use this channel)',
          remove: 'Remove push message',
          remove_modal: {
            title: 'Remove this push message',
            description: "You will lose any content you've created",
            cta: 'Remove push message',
          },
        },
        sms: {
          title: 'SMS message',
          add: 'Add SMS',
          edit: 'Edit SMS message',
          missing: 'No SMS',
          missing_no_redeem: 'No SMS (Include a reward to use this channel)',
          remove: 'Remove SMS message',
          remove_modal: {
            title: 'Remove this SMS message',
            description: "You will lose any content you've created",
            cta: 'Remove SMS message',
          },
        },
      },
    },
    incentive,
  },
  name: {
    edit: 'Edit campaign name',
    rename: 'Rename',
    placeholder: 'Name this variant',
  },

  confirm_modal: confirmModal,
  variants,
}
