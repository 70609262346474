import { Text } from '@thanx/uikit/text'
import {
  merchantUsersApi,
  Role,
  UpdateMerchantUserPayload,
} from 'api/merchantUsers'
import Spinner from 'components/Spinner'
import Formsy from 'formsy-react'
import { useDisplayMessage } from 'hooks/useDisplayMessage'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'react-router-redux'
import { useStyletron } from 'styletron-react'
import FormLayout from './FormLayout'
import MerchantUserForm from './MerchantUserForm'

type Props = {} & RouteComponentProps<{ id: string }>
const t = buildTranslate('merchant_users.edit')

const EditMerchantUser = ({ match }: Props) => {
  const dispatch = useDispatch()

  const merchantUserId = match.params.id

  const {
    data: { merchant_user: merchantUser } = {},
    isLoading,
    isSuccess,
  } = merchantUsersApi.useGetMerchantUserQuery(merchantUserId)

  const [updateMerchantUser, updateUserStatus] =
    merchantUsersApi.useUpdateMerchantUserMutation({
      fixedCacheKey: 'update',
    })

  const [sendInvitation, inviteUserStatus] =
    merchantUsersApi.useSendInvitationMutation({
      fixedCacheKey: 'invitation',
    })

  const [merchantUserPatch, setMerchantUserPatch] =
    useState<Partial<UpdateMerchantUserPayload>>()

  const displayUpdateStatusMessage = useDisplayMessage(updateUserStatus.reset)
  const displayInviteStatusMessage = useDisplayMessage(inviteUserStatus.reset)

  const [css] = useStyletron()

  useEffect(() => {
    if (isSuccess) {
      setMerchantUserPatch(prev => ({
        ...prev,
        role: merchantUser?.role,
        location_ids: merchantUser?.location_ids,
      }))
    }
  }, [merchantUser, isSuccess])

  return (
    <Spinner isLoading={isLoading}>
      <FormLayout
        isEditing
        canSendInvitation={merchantUser?.pending}
        onFinish={async () => {
          try {
            await updateMerchantUser({
              ...merchantUserPatch,
              id: +merchantUserId,
            }).unwrap()
            dispatch(push('/merchant_users'))
          } catch (err) {
            displayUpdateStatusMessage(t('error_generic'), 'danger')
          }
        }}
        onSendInvitation={async () => {
          try {
            await sendInvitation(merchantUserId).unwrap()
            dispatch(push('/merchant_users'))
          } catch (err) {
            displayInviteStatusMessage(t('edit.invitation_error'), 'danger')
          }
        }}
      >
        <Formsy role="form">
          <div className={css({ marginBottom: '40px' })}>
            {merchantUser?.first_name && merchantUser?.last_name && (
              <Text.Header2>{`${merchantUser.first_name} ${merchantUser.last_name}`}</Text.Header2>
            )}
            <Text.BodyText3 color="#6A747F">
              {merchantUser?.email}
            </Text.BodyText3>
          </div>

          {merchantUserPatch && (
            <MerchantUserForm
              merchantUser={merchantUserPatch}
              onChange={nextMerchantUser => {
                setMerchantUserPatch(prev => ({
                  ...prev,
                  location_ids: nextMerchantUser.location_ids,
                  role: nextMerchantUser.role as Role,
                }))
              }}
            />
          )}
        </Formsy>
      </FormLayout>
    </Spinner>
  )
}

export default EditMerchantUser
