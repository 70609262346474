import { useStyletron } from '@thanx/uikit/theme'
import HelpIcon from 'assets/images/nav/HelpIcon'
import React from 'react'
import { Nav } from 'react-bootstrap-five'
import { merchantTheme } from 'theme'
import { useIsDesktop } from 'utilities/responsive'
import { baseHelpUrl } from 'utilities/urlUtils'

const Help: React.FC = () => {
  const [css] = useStyletron()
  const isDesktop = useIsDesktop()

  return (
    <Nav.Item className="d-flex align-items-center">
      <a
        href={baseHelpUrl}
        target="_blank"
        rel="noreferrer"
        className={`d-flex ${css({
          padding: '15px ' + (isDesktop ? '8px' : '10px'),
          display: 'inline-block',
          'will-change': 'opacity, transform',
          transition: 'opacity 0.2s ease, transform 0.2s ease',
          color: merchantTheme.colors.grey30,
          ':hover': {
            backgroundColor: merchantTheme.colors.grey100,
            color: merchantTheme.colors.white,
          },
          ':focus': {
            backgroundColor: merchantTheme.colors.grey100,
            color: merchantTheme.colors.white,
          },
        })}`}
      >
        <HelpIcon />
      </a>
    </Nav.Item>
  )
}

export default Help
