import { Text } from '@thanx/uikit/text'
import Email from 'components/Icons/Email'
import Push from 'components/Icons/Push'
import Sms from 'components/Icons/Sms'
import useIsDemo from 'hooks/useIsDemo'
import { buildTranslate } from 'locales'
import { CampaignStateT } from 'models/Campaign'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCampaign } from 'selectors/campaign'
import Section from '../Section'
import Clicked from './Email/Clicked'
import EmailDelivered from './Email/Delivered'
import LinkActivity from './Email/LinkActivity'
import Opened from './Email/Opened'
import EmailSent from './Email/Sent'
import Unsubscribed from './Email/Unsubscribed'
import PushDelivered from './Push/Delivered'
import PushSent from './Push/Sent'
import SmsDelivered from './Sms/Delivered'
import SmsSent from './Sms/Sent'

const t = buildTranslate('thanx_campaigns.report.v3.summary.engagement')
type Props = {
  campaignId: number
  campaignState: CampaignStateT
}
const Engagement: React.FC<Props> = ({ campaignId, campaignState }) => {
  const isDemo = useIsDemo()

  const campaign = useSelector(state => selectCampaign(state, campaignId))

  const hasSMS =
    !isDemo &&
    (campaign?.notify_sms ||
      campaign?.variants.some(({ notify_sms }) => notify_sms))

  return (
    <Section
      title={t('title')}
      attributionWindow={-1}
      attributionTooltip={t('attribution.tooltip')}
      subtitle={t(hasSMS ? 'subtitle' : 'no_sms_subtitle')}
      campaignId={campaignId}
      metricTypes={[
        'email_message_count',
        'link_activity',
        'push_message_count',
        'sms_message_count',
      ]}
      campaignState={campaignState}
    >
      <div className="d-flex flex-align-center mb-xs">
        <Email />
        <Text.Header4 className="m-0 ml-s">{t('email.title')}</Text.Header4>
      </div>
      <div className="mb-xl">
        <EmailSent campaignId={campaignId} />
        <EmailDelivered campaignId={campaignId} />
        <Opened campaignId={campaignId} />
        <Clicked campaignId={campaignId} />
        <Unsubscribed campaignId={campaignId} />
      </div>
      <LinkActivity campaignId={campaignId} />

      {hasSMS ? (
        <>
          <div className="d-flex flex-align-center mb-xs">
            <Sms />
            <Text.Header4 className="m-0 ml-s">{t('sms.title')}</Text.Header4>
          </div>
          <div className="mb-xl">
            <SmsSent campaignId={campaignId} />
            <SmsDelivered campaignId={campaignId} />
          </div>
        </>
      ) : null}

      <div className="d-flex flex-align-center mb-xs">
        <Push />
        <Text.Header4 className="m-0 ml-s">{t('push.title')}</Text.Header4>
      </div>
      <div className="mb-xl">
        <PushSent campaignId={campaignId} />
        <PushDelivered campaignId={campaignId} />
      </div>
    </Section>
  )
}

export default Engagement
