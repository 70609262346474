export default {
  urls: {
    generic: {
      title: 'In-progress report',
    },
    'dashboards/76': {
      title: 'Average check dashboard',
    },
    'dashboards/86': {
      title: 'Ordering conversion',
      tabs: {
        cart_conversion: 'Cart conversion',
        unaccepted_carts: 'Unaccepted carts',
      },
    },
    'dashboards/106': {
      title: 'Overview',
      tabs: {
        ratings: 'Rating details',
        reviews: 'Review details',
      },
    },
    'dashboards/114': {
      title: 'Average check',
      tabs: {
        overview: 'Overview',
        handoff_mode: 'Handoff mode',
        location: 'Location',
      },
    },
    'dashboards/269': {
      title: 'Campaign performance',
      tabs: {
        email_engagement_overview: 'Email engagement overview',
        email_engagement_by_campaign_type: 'Email engagement by campaign type',
      },
    },
    'dashboards/120': {
      title: 'App downloads',
    },
    'dashboards/122': {
      title: 'Frequency',
    },
    'dashboards/130': {
      title: 'Engagement',
      tabs: {
        overview: 'Overview',
        average_monthly_value: 'Average Monthly Value',
      },
    },
    'dashboards/131': {
      title: 'Overview',
    },
    'dashboards/240': {
      title: 'Tiers',
      tabs: {
        overview: 'Overview',
        activity: 'Activity',
        configuration: 'Configuration',
      },
    },
    'looks/356': {
      title: 'Ordering errors',
    },
  },
  tiers: {
    title: 'Configuration',
    subtext:
      'A flexible way to reward your long-time customers without relying solely on discounts.',
  },
  olo_toast_only:
    'This report is only available for brands that use Olo or Toast as their ordering provider',
  forbidden: 'You don’t have permission to view this page',
  badge: 'Looker labs',
  footer: {
    text: 'Have suggestions for this report or ideas for a new one?',
    button: 'Submit a feature request',
  },
  order_conversion: {
    one_click: {
      title: 'Recover abandoned carts',
      subtitle: 'Set up an automated campaign',
      button: 'Start draft',
      how_to: 'Learn more',
      description:
        'Encourage customers that abandoned their cart to return and complete their purchase.',
      going_forward:
        'Going forward, automatically message customers when they enter the segment:',
      submit_error:
        'There was an error creating your custom campaign. Please try again.',
    },
  },
}
