import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import { initializeCampaignVariant } from 'actions/campaignVariants'
import Input from 'components/Form/Input'
import Import from 'components/Icons/Import'
import PopoverContent from 'components/PopoverContent'
import useDispatch from 'hooks/useDispatch'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import { Fields as NotifyEmail } from 'models/NotifyEmail'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import FormModelContext from 'scenes/CampaignCenter/Builder/components/FormModelContext'
import EmailEditor from 'scenes/CampaignCenter/Builder/components/Forms/EmailEditor'
import PushForm from 'scenes/CampaignCenter/Builder/components/Forms/Push'
import MessageTab from 'scenes/CampaignCenter/Builder/components/MessageTab'
import PushPreview from 'scenes/CampaignCenter/Builder/components/Previews/Push'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import ImportModal from './ImportModal'
import PreviewModal from './PreviewModal'
import PreviewNotifyEmailModal from './PreviewNotifyEmailModal'
import SendTestModal from './SendTestModal'

type Props = {
  campaign: Campaign
  showValidationErrors?: () => void
}

const MessagingEditor: React.FC<Props> = ({
  campaign,
  showValidationErrors,
}) => {
  const formModel: any = useContext(FormModelContext)

  const [css] = useStyletron()
  const [isCopying, setIsCopying] = useState(false)
  const [showSendTestModal, setShowSendTestModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [showPreviewNotifyModal, setShowPreviewNotifyModal] = useState(false)
  const [selectedNotifyEmail, setSelectedNotifyEmail] =
    useState<NotifyEmail | null>(null)
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  const dispatch = useDispatch()

  const unlayerEnabled = campaignType?.unlayer_enabled
  const { config } = useCampaignConfig()
  const t = buildTranslate('thanx_campaigns.builder.forms.messaging_tabs')

  function pushForm(): React.ReactNode | null {
    if (!config.redeem) return null
    const left = (
      <div className="display-flex flex-justify-center">
        <PushPreview text={formModel.notify_push_text} />
      </div>
    )
    const right = (
      <>
        <Input type="hidden" value={true} name="notify_push" />
        <PushForm campaign={campaign} />
      </>
    )
    return twoColumn(left, right)
  }

  function twoColumn(
    left: React.ReactNode,
    right: React.ReactNode
  ): React.ReactNode {
    return (
      <Row>
        <Col
          md={6}
          className="padding-top-huge full-height-without-navs overflow-y"
        >
          {left}
        </Col>
        <Col
          md={6}
          className={`
            grey-10-bkg padding-top-huge
            padding-left-extra-large padding-right-extra-large
            full-height-without-navs overflow-y
          `}
        >
          {right}
        </Col>
      </Row>
    )
  }

  const isEmailCopyingEnabled = useFlag('MX4-Copy-An-Email', false)
  const copyButtonLabel = isEmailCopyingEnabled
    ? 'thanx_campaigns.builder.forms.messaging_tabs.import.title_v2'
    : 'thanx_campaigns.builder.forms.messaging_tabs.import.title'

  useEffect(() => {
    // If the config doesn't have email, initialize it.
    if (!config.notify_email && config.campaign_config_type === 'variant') {
      dispatch(initializeCampaignVariant(campaign.id, config.id))
    }
  }, [
    campaign.id,
    config.notify_email,
    config.id,
    config.campaign_config_type,
    dispatch,
  ])

  function navItems(): React.ReactNode | null {
    if (!unlayerEnabled) return null
    const isABTest =
      config.campaign_config_type === 'variant' && campaign.variants?.length > 1
    const importText = isABTest
      ? t('import.tooltip')
      : t('import.not_ab_tooltip')

    const isCopyButtonEnabled = isEmailCopyingEnabled || isABTest

    return (
      <Tab.Content animation={false}>
        <Tab.Pane eventKey="email">
          <div className="inline-block padding-right-medium">
            <Button
              bsStyle="link"
              className="font-size-16"
              onClick={() => setShowPreviewModal(true)}
            >
              <i className="fa fa-eye margin-right-small" />
              <Translate value="thanx_campaigns.builder.forms.messaging_tabs.preview.title" />
            </Button>
          </div>
          <div className="inline-block padding-right-medium">
            <Button
              bsStyle="link"
              className={`font-size-16 ${css({
                ':disabled': {
                  color: defaultTheme.colors.grey70,
                  backgroundColor: 'transparent',
                },
              })}`}
              onClick={() => setShowImportModal(true)}
              disabled={!isCopyButtonEnabled}
            >
              <Import className="mr-xs mb-xxs" />
              <Translate value={copyButtonLabel} />
            </Button>
            {!isEmailCopyingEnabled && (
              <PopoverContent sections={[{ content: importText }]} />
            )}
          </div>
          <div className="inline-block">
            <Button
              bsStyle="link"
              className="font-size-16"
              onClick={() => setShowSendTestModal(true)}
            >
              <i className="fa fa-external-link margin-right-small" />
              <Translate value="thanx_campaigns.builder.forms.messaging_tabs.send_test.title" />
            </Button>
          </div>
        </Tab.Pane>
      </Tab.Content>
    )
  }

  const refresh = () => {
    setIsCopying(true)
    setTimeout(() => {
      setIsCopying(false)
    }, 1000)
  }

  return (
    <>
      <SendTestModal
        campaign={campaign}
        show={showSendTestModal}
        onModalClose={() => setShowSendTestModal(false)}
      />
      <PreviewModal
        campaign={campaign}
        show={showPreviewModal}
        onModalClose={() => setShowPreviewModal(false)}
      />
      <ImportModal
        config={config}
        campaign={campaign}
        show={showImportModal}
        onSelectPreview={(notifyEmail: NotifyEmail) => {
          setSelectedNotifyEmail(notifyEmail)
          setShowImportModal(false)
          setShowPreviewNotifyModal(true)
        }}
        onModalClose={() => {
          setShowImportModal(false)
          refresh()
        }}
      />
      <PreviewNotifyEmailModal
        show={showPreviewNotifyModal}
        campaign={campaign}
        config={config}
        notifyEmail={selectedNotifyEmail}
        onBack={() => {
          setShowPreviewNotifyModal(false)
          setShowImportModal(true)
        }}
        onClose={() => {
          setShowPreviewNotifyModal(false)
          refresh()
        }}
      />
      <MessageTab
        triggerValidationOnClick
        className="border-bottom-1 grey-20-border margin-bottom-none"
        emailOnly={!config.redeem}
        showTooltips={true}
        showValidationErrors={showValidationErrors}
        navItems={navItems()}
      >
        <Tab.Content animation={false}>
          <Tab.Pane eventKey="email">
            <Input type="hidden" value={true} name="notify_email" />
            <EmailEditor campaign={campaign} isReloading={isCopying} />
          </Tab.Pane>
          <Tab.Pane eventKey="push">{pushForm()}</Tab.Pane>
        </Tab.Content>
      </MessageTab>
    </>
  )
}

export default MessagingEditor
