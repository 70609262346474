import { alert } from 'actions/flash'
import { legalDocsApi, LegalDocTypeEnum } from 'api/legalDocs'
import palette from 'constants/palette'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { push } from 'react-router-redux'
import CmsPage from 'scenes/Cms/components/CmsPage'
import CCPACard from './components/CCPACard'
import PrivacyPolicyCard from './components/PrivacyPolicyCard'
import TermsOfServiceCard from './components/TermsOfServiceCard'
import { getLegalDocByType, getPolicySubtitle, getPolicyUrl } from './helpers'

const t = buildTranslate('cms.settings.terms_and_policies')

const TermsAndPolicies: React.FC = () => {
  const [showPreviewModalPrivacyPolicy, setShowPreviewModalPrivacyPolicy] =
    useState(false)
  const [showPreviewModalTermsOfService, setShowPreviewModalTermsOfService] =
    useState(false)
  const [showPreviewModalCCPA, setShowPreviewModalCCPA] = useState(false)

  const currentMerchant = useCurrentMerchant()

  const {
    data: { legal_docs: legalDocs } = { legal_docs: [] },
    isLoading,
    isError,
  } = legalDocsApi.useGetLegalDocsQuery()
  const privacyPolicyDoc = getLegalDocByType(
    legalDocs,
    LegalDocTypeEnum.PRIVACY_POLICY
  )
  const termsOfServiceDoc = getLegalDocByType(
    legalDocs,
    LegalDocTypeEnum.TERMS_OF_SERVICE
  )
  const ccpaDoc = getLegalDocByType(legalDocs, LegalDocTypeEnum.CCPA)

  const dispatch = useDispatch()

  const urlCustomText = t('url_custom_text')
  const customText = t('custom_text')

  const privacyPolicySubtitle = getPolicySubtitle(
    privacyPolicyDoc,
    t('privacy_policy.default_subtitle'),
    urlCustomText,
    customText
  )
  const termsOfServiceSubtitle = getPolicySubtitle(
    termsOfServiceDoc,
    t('terms_of_service.default_subtitle'),
    urlCustomText,
    customText
  )
  const ccpaSubtitle = getPolicySubtitle(
    ccpaDoc,
    t('ccpa.default_subtitle'),
    urlCustomText,
    customText
  )

  const bgColorPrivacyPolicyCard = privacyPolicyDoc
    ? palette.white
    : palette.grey10
  const bgColorTermsOfServiceCard = termsOfServiceDoc
    ? palette.white
    : palette.grey10
  const bgColorCCPACard = ccpaDoc ? palette.white : palette.grey10

  const editButtonTextPrivacyPolicyCard = privacyPolicyDoc
    ? t('privacy_policy.edit_btn_text')
    : t('privacy_policy.edit_default_btn_text')
  const editButtonTextTermsOfServiceCard = termsOfServiceDoc
    ? t('terms_of_service.edit_btn_text')
    : t('terms_of_service.edit_default_btn_text')
  const editButtonTextCCPACard = ccpaDoc
    ? t('ccpa.edit_btn_text')
    : t('ccpa.edit_default_btn_text')

  const urlPrivacyPolicy = getPolicyUrl(privacyPolicyDoc)
  const urlTermsOfService = getPolicyUrl(termsOfServiceDoc)
  const urlCCPA = getPolicyUrl(ccpaDoc)

  const handlePrivacyPolicyViewBtnClick = () => {
    if (privacyPolicyDoc && privacyPolicyDoc.thanx_hosted) {
      setShowPreviewModalPrivacyPolicy(true)
      return
    }

    let url = `/privacy/${currentMerchant?.handle}`
    if (privacyPolicyDoc && !privacyPolicyDoc.thanx_hosted) {
      url = privacyPolicyDoc.url || url
    }

    window.open(url, '_blank')
  }
  const handlePrivacyPolicyEditBtnClick = () => {
    dispatch(
      push(
        `/brand_content/terms_and_policies_builder/${LegalDocTypeEnum.PRIVACY_POLICY}`
      )
    )
  }
  const handleTermsOfServiceViewBtnClick = () => {
    if (termsOfServiceDoc && termsOfServiceDoc.thanx_hosted) {
      setShowPreviewModalTermsOfService(true)
      return
    }

    let url = `/terms/${currentMerchant?.handle}`

    if (termsOfServiceDoc && !termsOfServiceDoc.thanx_hosted) {
      url = termsOfServiceDoc.url || url
    }

    window.open(url, '_blank')
  }
  const handleTermsOfServiceEditBtnClick = () => {
    dispatch(
      push(
        `/brand_content/terms_and_policies_builder/${LegalDocTypeEnum.TERMS_OF_SERVICE}`
      )
    )
  }
  const handleCCPAViewBtnClick = () => {
    if (ccpaDoc && ccpaDoc.thanx_hosted) {
      setShowPreviewModalCCPA(true)
      return
    }

    let url = `/ccpaoptout/${currentMerchant?.handle}`
    if (ccpaDoc && !ccpaDoc.thanx_hosted) {
      url = ccpaDoc.url || url
    }

    window.open(url, '_blank')
  }
  const handleCCPAEditBtnClick = () => {
    dispatch(
      push(`/brand_content/terms_and_policies_builder/${LegalDocTypeEnum.CCPA}`)
    )
  }

  useEffect(() => {
    if (isError) {
      dispatch(
        alert({
          key: 'danger',
          message: t('errors.load_error'),
          timeout: 3,
        })
      )
    }
  }, [isError])

  return (
    <CmsPage
      data-testid="cms-page"
      data-loading={isLoading}
      title={t('title')}
      description={t('description')}
    >
      <PrivacyPolicyCard
        key="privacy-policy-card"
        title={t('privacy_policy.title')}
        subtitle={privacyPolicySubtitle}
        viewButtonText={t('privacy_policy.view_btn_text')}
        editButtonText={editButtonTextPrivacyPolicyCard}
        bgColor={bgColorPrivacyPolicyCard}
        url={urlPrivacyPolicy}
        lastUpdatedDate={privacyPolicyDoc?.updated_at}
        isShowingPreview={showPreviewModalPrivacyPolicy}
        onViewClick={handlePrivacyPolicyViewBtnClick}
        onEditClick={handlePrivacyPolicyEditBtnClick}
        onPreviewClose={() => setShowPreviewModalPrivacyPolicy(false)}
        content={privacyPolicyDoc?.html || ''}
      />

      <TermsOfServiceCard
        key="terms-of-service-card"
        title={t('terms_of_service.title')}
        subtitle={termsOfServiceSubtitle}
        viewButtonText={t('terms_of_service.view_btn_text')}
        editButtonText={editButtonTextTermsOfServiceCard}
        bgColor={bgColorTermsOfServiceCard}
        url={urlTermsOfService}
        lastUpdatedDate={termsOfServiceDoc?.updated_at}
        isShowingPreview={showPreviewModalTermsOfService}
        onViewClick={handleTermsOfServiceViewBtnClick}
        onEditClick={handleTermsOfServiceEditBtnClick}
        onPreviewClose={() => setShowPreviewModalTermsOfService(false)}
        content={termsOfServiceDoc?.html || ''}
      />

      <CCPACard
        key="ccpa-card"
        title={t('ccpa.title')}
        subtitle={ccpaSubtitle}
        viewButtonText={t('ccpa.view_btn_text')}
        editButtonText={editButtonTextCCPACard}
        bgColor={bgColorCCPACard}
        url={urlCCPA}
        lastUpdatedDate={ccpaDoc?.updated_at}
        isShowingPreview={showPreviewModalCCPA}
        onViewClick={handleCCPAViewBtnClick}
        onEditClick={handleCCPAEditBtnClick}
        onPreviewClose={() => setShowPreviewModalCCPA(false)}
        content={ccpaDoc?.html || ''}
      />
    </CmsPage>
  )
}

export default TermsAndPolicies
