import { MergeTag } from 'api/mergeTag'
import { MergeTags as UnlayerMergeTags } from 'state/types/types'

export type MergeTagItem = {
  id: number
  mergeTagType: string
  defaultValue: string
}

export const getMergeTagTypeName = (mergeTagType: string) => {
  if (!mergeTagType) {
    return ''
  }
  return mergeTagType
    .replace(/_/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase())
}

export const getCurrentMergeTagsMap = (
  currentMergeTagsMap: Map<number, MergeTagItem> = new Map(),
  newMergeTags: MergeTag[]
): Map<number, MergeTagItem> => {
  newMergeTags.forEach(newMergeTag => {
    const currentMergeTagItem = currentMergeTagsMap.get(newMergeTag.id)
    if (currentMergeTagItem) {
      currentMergeTagItem.defaultValue =
        currentMergeTagItem.defaultValue || newMergeTag.default_value
      currentMergeTagsMap.set(newMergeTag.id, currentMergeTagItem)
      return
    }
    const newMergeTagItem: MergeTagItem = {
      id: newMergeTag.id,
      mergeTagType: newMergeTag.merge_tag_type,
      defaultValue: newMergeTag.default_value,
    }
    currentMergeTagsMap.set(newMergeTag.id, newMergeTagItem)
  })

  return new Map<number, MergeTagItem>(currentMergeTagsMap)
}

export const getUnlayerMergeTags = (
  mergeTags?: MergeTag[]
): UnlayerMergeTags | undefined => {
  if (!mergeTags || !mergeTags.length) {
    return
  }

  const unlayerMergeTags: UnlayerMergeTags = {}
  mergeTags.forEach(({ merge_tag_type, default_value }) => {
    const name = getMergeTagTypeName(merge_tag_type)
    unlayerMergeTags[merge_tag_type] = {
      name,
      value: `{{${merge_tag_type}}}`,
      sample: default_value,
    }
  })

  return unlayerMergeTags
}
