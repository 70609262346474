import { alert } from 'actions/flash'
import { legalDocsApi, type LegalDoc, type LegalDocType } from 'api/legalDocs'
import Formsy from 'formsy-react'
import { buildTranslate } from 'locales'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { errorTimeoutInSeconds, getActiveTab, Tabs } from '../helpers'

const t = buildTranslate('cms.settings.terms_and_policies')

export const useTermsAndPoliciesBuilder = (
  policyDoc: LegalDoc | undefined,
  legalDocType: LegalDocType
) => {
  const dispatch = useDispatch()
  const docContentForm = useRef<Formsy | null>(null)
  const urlForm = useRef<Formsy | null>(null)

  const [isDocContentFormValid, setIsDocContentFormValid] = useState(false)
  const [activeTab, setActiveTab] = useState<Tabs>(getActiveTab(policyDoc))
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [temporyPolicyDoc, setTemporyPolicyDoc] = useState<LegalDoc | null>(
    policyDoc || null
  )

  const [
    updateLegalDoc,
    {
      isLoading: isSaving,
      isSuccess: isSaved,
      error: saveError,
      data: { legal_doc: updatedLegalDoc } = { legal_doc: null },
    },
  ] = legalDocsApi.useUpdateLegalDocMutation()
  const [
    createLegalDoc,
    {
      isLoading: isCreating,
      isSuccess: isCreated,
      error: createError,
      data: { legal_doc: createdLegalDoc } = { legal_doc: null },
    },
  ] = legalDocsApi.useCreateLegalDocMutation()
  const [
    publishLegalDoc,
    { isLoading: isPublishing, isSuccess: isPublished, error: publishError },
  ] = legalDocsApi.usePublishLegalDocMutation()
  const [
    previewLegalDoc,
    { isLoading: isPreviewing, error: previewError, data: previewData },
  ] = legalDocsApi.usePreviewLegalDocMutation()

  // Error handling effect
  useEffect(() => {
    const errors = [
      { error: previewError, message: 'errors.documentbuilder.preview_error' },
      { error: publishError, message: 'errors.documentbuilder.publish_error' },
      { error: saveError, message: 'errors.documentbuilder.save_error' },
      { error: createError, message: 'errors.documentbuilder.create_error' },
    ]

    errors.forEach(({ error, message }) => {
      if (error) {
        dispatch(
          alert({
            key: 'danger',
            message: t(message),
            timeout: errorTimeoutInSeconds,
          })
        )
      }
    })
  }, [previewError, publishError, saveError, createError])

  const debouncedSave = useCallback(
    debounce((content: string) => {
      if (temporyPolicyDoc) {
        setTemporyPolicyDoc({
          ...temporyPolicyDoc,
          ...(activeTab === Tabs.ENTER_CUSTOM_LEGAL_DOC_TEXT
            ? { configuration: content }
            : { url: content }),
        })
      }
    }, 950),
    [temporyPolicyDoc, activeTab]
  )

  const handleFormChange = (model: any) => {
    const data =
      activeTab === Tabs.PROVIDE_LEGAL_DOC_URL
        ? model.url
        : model.documentContent
    if (isDocContentFormValid && data) {
      debouncedSave(data)
    }
  }

  const handlePublish = async () => {
    try {
      if (!policyDoc) {
        const payload =
          activeTab === Tabs.ENTER_CUSTOM_LEGAL_DOC_TEXT
            ? {
                legal_doc_type: legalDocType,
                configuration:
                  docContentForm.current?.getModel().documentContent,
                thanx_hosted: true,
                state: 'draft' as const,
              }
            : {
                legal_doc_type: legalDocType,
                url: urlForm.current?.getModel().url,
                thanx_hosted: false,
                state: 'draft' as const,
              }
        createLegalDoc(payload)
        return
      }

      if (policyDoc?.id) {
        const payload =
          activeTab === Tabs.ENTER_CUSTOM_LEGAL_DOC_TEXT
            ? {
                id: policyDoc.id,
                configuration: temporyPolicyDoc?.configuration,
                thanx_hosted: true,
              }
            : {
                id: policyDoc.id,
                url: temporyPolicyDoc?.url,
                thanx_hosted: false,
              }
        updateLegalDoc(payload)
      }
    } catch (error) {
      dispatch(
        alert({
          key: 'danger',
          message: t('errors.documentbuilder.publish_error'),
          timeout: errorTimeoutInSeconds,
        })
      )
      setShowPublishModal(false)
    }
  }

  // Publication effects
  useEffect(() => {
    if (isCreated && createdLegalDoc) {
      publishLegalDoc({ id: createdLegalDoc.id })
    }
  }, [createdLegalDoc, isCreated, publishLegalDoc])

  useEffect(() => {
    if (isSaved && updatedLegalDoc) {
      publishLegalDoc({ id: updatedLegalDoc?.id })
    }
  }, [updatedLegalDoc, isSaved, publishLegalDoc])

  useEffect(() => {
    if (isPublished) {
      setShowPublishModal(false)
      dispatch(push('/brand_content#terms_and_policies'))
    }
  }, [isPublished])

  useEffect(() => {
    if (policyDoc) {
      setActiveTab(getActiveTab(policyDoc))
      setTemporyPolicyDoc(policyDoc)
    }
  }, [policyDoc])

  return {
    docContentForm,
    urlForm,
    isDocContentFormValid,
    setIsDocContentFormValid,
    activeTab,
    setActiveTab,
    showPreviewModal,
    setShowPreviewModal,
    showPublishModal,
    setShowPublishModal,
    temporyPolicyDoc,
    handleFormChange,
    handlePublish,
    isFormSaving: isCreating || isPublishing || isSaving,
    isPreviewing,
    previewData,
    previewLegalDoc,
  }
}
