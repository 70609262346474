import { StyleableButton } from '@thanx/uikit/button'
import { FormsyInput } from '@thanx/uikit/input'
import { FormsyRadioGroup, Radio } from '@thanx/uikit/radio'
import { FormsySelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import FakeInput from 'components/Form/FakeInput'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import sortBy from 'lodash/sortBy'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { getUrlValue } from 'scenes/Cms/helper'
import { selectExclusiveDeals } from 'selectors/exclusiveDeal'

export enum UrlType {
  NoLink = 'no_link',
  External = 'external',
  Inapp = 'inapp',
}

export type UrlValue = {
  inapp: string
  external: string
  fullUrl?: string
  type: UrlType
  authenticable: boolean
  queryParams?: string
}

type Props = {
  id: string
  type: 'button' | 'image'
  url: UrlValue
  required?: boolean
  hasQueryParam?: boolean
}

const t = buildTranslate('cms.components.url_picker')

export type Url = {
  name: string
  link: string
  label?: string
  alt_label?: boolean
}

export const DefaultUrls: Url[] = [
  {
    name: 'about',
    link: 'thanx://about',
  },
  {
    name: 'account',
    link: 'thanx://account',
  },
  {
    name: 'locations',
    link: 'thanx://locations',
  },
  {
    name: 'nps',
    link: 'thanx://nps',
  },
  {
    name: 'notifications',
    link: 'thanx://notifications',
  },
  {
    name: 'registered_cards',
    link: 'thanx://registered_cards',
  },
  {
    name: 'rewards',
    link: 'thanx://rewards',
  },
  {
    name: 'support',
    link: 'thanx://support',
  },
]

export const OrderUrls: Url[] = [
  {
    name: 'menu',
    link: 'thanx://ordering-menu',
    alt_label: true,
  },
  {
    name: 'history',
    link: 'thanx://order-history',
  },
]

export const EarnUrls: Url[] = [
  {
    name: 'earn',
    link: 'thanx://earn',
  },
]

export const ExclusiveDealUrls: Url[] = [
  {
    name: 'shop',
    link: 'thanx://shop',
  },
]

const UrlPicker: React.FC<Props> = props => {
  const { id, type, url, required = true, hasQueryParam } = props
  const externalUrl = url.external || ''
  const fullUrl = url.fullUrl || externalUrl
  const { app_ordering_enabled } = useCurrentMerchant() || {}
  const deals = useSelector(selectExclusiveDeals)

  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false)
  const [urlValue, setUrlValue] = useState(externalUrl)
  const [authenticable, setAuthenticable] = useState(url.authenticable)

  const urlOptions = getAvailableUrls()

  function getAvailableUrls() {
    let availableUrls: Url[] = DefaultUrls

    if (deals.length > 0) {
      availableUrls = availableUrls.concat(ExclusiveDealUrls)
    }

    if (app_ordering_enabled) {
      availableUrls = availableUrls.concat(OrderUrls)
    }

    availableUrls = availableUrls.concat(EarnUrls)

    return sortBy(
      availableUrls.map(url => {
        return {
          label:
            hasQueryParam && url.alt_label ? t(`${url.name}_alt`) : t(url.name),
          value: url.link,
        }
      }),
      ['label']
    )
  }

  useEffect(() => {
    setUrlValue(externalUrl)
  }, [externalUrl])

  function removeLink() {
    setUrlValue('')
    setAuthenticable(false)
  }
  const isAuthenticable = authenticable && !!urlValue
  return (
    <>
      <FormsyRadioGroup
        name={`${id}.url.type`}
        label={t('label', { type })}
        align="horizontal"
        value={url.type}
      >
        {!required && (
          <Radio className="mr-s" value={UrlType.NoLink}>
            {t('no_link')}
          </Radio>
        )}
        <Radio className="mr-s" value={UrlType.External}>
          {t('url')}
        </Radio>
        <Radio value={UrlType.Inapp}>{t('page_within')}</Radio>
      </FormsyRadioGroup>
      <Switch condition={url.type === UrlType.External}>
        <Switch
          condition={isAuthenticable}
          fallback={
            <FormsyInput
              name={`${id}.url.external`}
              value={urlValue}
              validations={{
                isRequired: true,
                isUrl: true,
              }}
              validationErrors={{
                isRequired: I18n.t('validations.is_required'),
                isUrl: I18n.t('validations.is_url'),
              }}
            />
          }
        >
          <FakeInput
            text={getUrlValue(urlValue, isAuthenticable).external}
            disabled
            button={t('remove_link')}
            clickPassthrough={removeLink}
          />
          <FormsyInput
            className="hidden"
            name={`${id}.url.external`}
            value={fullUrl}
          />
        </Switch>
      </Switch>
      <Switch condition={url.type === UrlType.Inapp}>
        <FormsySelect
          name={`${id}.url.inapp`}
          value={url.inapp || urlOptions[0].value}
          options={urlOptions}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: I18n.t('validations.is_required'),
          }}
        />
      </Switch>
      <FormsyInput
        className="hidden"
        name={`${id}.url.authenticable`}
        value={authenticable}
      />
      <Switch condition={url.type === UrlType.External}>
        <div className="d-flex flex-column">
          <Switch
            condition={!isAuthenticable}
            fallback={
              <p className="mt-m p-0 float-left font-size-12 belize-hole-50">
                {t('advanced')}
              </p>
            }
          >
            <StyleableButton
              className="mt-m p-0 float-left font-size-12 belize-hole-50 text-left"
              onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
            >
              {t('advanced')}
              <i
                className={`fa ${
                  isAdvancedOpen ? 'fa-caret-up' : 'fa-caret-down'
                } ml-xs`}
              />
            </StyleableButton>
          </Switch>
          <Switch condition={isAdvancedOpen || isAuthenticable}>
            <p
              className="body-text-5 mt-xxs float-left grey-70 text-left"
              dangerouslySetInnerHTML={{
                __html: isAuthenticable
                  ? t('advanced_link_enabled')
                  : t('advanced_link_disabled'),
              }}
            />
          </Switch>
        </div>
      </Switch>
    </>
  )
}

export default UrlPicker
