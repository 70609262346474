import {
  LegalDocTypeEnum,
  type LegalDoc,
  type LegalDocType,
} from 'api/legalDocs'
import { buildTranslate } from 'locales'

const t = buildTranslate('cms.settings.terms_and_policies')

export const getLegalDocByType = (
  legalDocs: LegalDoc[],
  type: LegalDocType
): LegalDoc | null => {
  return legalDocs.find(doc => doc.legal_doc_type === type) || null
}

export const getPolicySubtitle = (
  policyDoc: LegalDoc | null,
  subtitle: string,
  urlCustomText: string,
  customText: string
): string => {
  if (!policyDoc) {
    return subtitle
  }
  return !policyDoc.thanx_hosted ? urlCustomText : customText
}

export const getPolicyUrl = (policyDoc: LegalDoc | null): string => {
  if (policyDoc && !policyDoc.thanx_hosted) {
    return policyDoc.url || ''
  }
  return ''
}

export enum Tabs {
  // ENTER_CUSTOM_LEGAL_DOC_TEXT = 'enter_custom_text',
  // PROVIDE_LEGAL_DOC_URL = 'provide_a_url',

  ENTER_CUSTOM_LEGAL_DOC_TEXT = 'enter_custom_text',
  PROVIDE_LEGAL_DOC_URL = 'provide_a_url',
}

export const getActiveTab = (policyDoc: LegalDoc | undefined) => {
  if (!policyDoc || policyDoc.thanx_hosted) {
    return Tabs.ENTER_CUSTOM_LEGAL_DOC_TEXT
  }

  return Tabs.PROVIDE_LEGAL_DOC_URL
}

export const getSubtitle = (legal_doc_type: LegalDocType) => {
  let subtitle = ''
  switch (legal_doc_type) {
    case LegalDocTypeEnum.PRIVACY_POLICY:
      subtitle = t('privacy_policy.title')
      break
    case LegalDocTypeEnum.TERMS_OF_SERVICE:
      subtitle = t('terms_of_service.title')
      break
    case LegalDocTypeEnum.CCPA:
      subtitle = t('ccpa.title')
      break
  }
  return subtitle
}

export const errorTimeoutInSeconds = 3
