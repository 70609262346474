import { Fields as Campaign } from 'models/Campaign'
import React from 'react'

const RedeemDescription = ({ description }) => {
  if (description) {
    return description.trim()
  } else {
    return <Dash />
  }
}

const Variants = ({ variants }) => {
  return (
    <ul style={{ padding: 0, margin: 0 }}>
      {variants.map((variant, index: number) => {
        const label: string = variant.name || variant.meta?.name
        return (
          <li key={index} style={{ listStyleType: 'none', margin: 0 }}>
            <RedeemDescription description={label} />
          </li>
        )
      })}
    </ul>
  )
}

const Dash = () => <>&mdash;</>

type Props = {
  campaign: Campaign
}

const RewardCell: React.FC<Props> = ({ campaign }) => {
  if (campaign.redeem_description) {
    return <RedeemDescription description={campaign.redeem_description} />
  }

  if (campaign.variants.length > 0) {
    return <Variants variants={campaign.variants} />
  }

  return <Dash />
}

export default RewardCell
