import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Flash from 'components/Flash'
import { useHeaderContext } from 'components/Header'
import React, { useEffect } from 'react'

type PropsT = {
  bkgClass?: string
  title: string | React.ReactNode
  children?: React.ReactNode
  shouldNotHide?: boolean
  className?: string
}

const EditNavbar: React.FC<PropsT> = ({
  children = null,
  bkgClass = 'grey-90-bkg',
  shouldNotHide = false,
  title,
  className = '',
}) => {
  const [css] = useStyletron()
  const { setShouldShow } = useHeaderContext()
  useEffect(() => {
    if (!shouldNotHide) {
      setShouldShow(false)
    }
    return () => setShouldShow(true)
  }, [shouldNotHide, setShouldShow])
  return (
    <div
      className={`subnav position-fixed px-m white py-s full-width ${css({
        zIndex: !shouldNotHide ? 1000 : undefined,
      })} ${bkgClass} ${className}`}
    >
      {!shouldNotHide && (
        <div
          className={`w-100 position-fixed ${css({
            top: 0,
            left: 0,
            zIndex: 1001,
          })}`}
        >
          <Flash />
        </div>
      )}
      <div className="d-flex align-items-center">
        <Text.Header4 className="flex-1 my-0" color="white">
          {title}
        </Text.Header4>
        {children}
      </div>
    </div>
  )
}

export default EditNavbar
