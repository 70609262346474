import { FormsyRadioGroup, Radio } from '@thanx/uikit/radio'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { getMerchantCouponPools } from 'actions/merchantCouponPools'
import PopoverInfo from 'components/PopoverInfo'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { RedemptionProvider } from 'models/RedemptionProvider'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isLoyaltyIntegrationPos } from 'scenes/RedeemManager/helpers'
import { InstoreRedemptionType } from 'scenes/RedeemManager/types'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'
import { useStyletron } from 'styletron-react'
import { useFormModel } from '../../FormModel'
import CouponCodesForm from '../InstoreRedemptionCard/CouponCodesForm'
import ThanxGeneratedEnabled from '../InstoreRedemptionCard/CouponCodesForm/ThanxGeneratedEnabled'

type Props = {
  redemptionProvider: RedemptionProvider
  templateId: number
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card'
)

function Form({ redemptionProvider, templateId }: Props) {
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const { model: formModel } = useFormModel()
  const { posFields, typeItem } = formModel

  const { instoreRedemptionType, couponCodes, selectedCouponPools } =
    posFields[redemptionProvider.value] ?? {}

  const merchant = useCurrentMerchant()
  const template = useSelector(store => selectRedeemTemplate(store, templateId))

  const isCouponCode =
    instoreRedemptionType === InstoreRedemptionType.COUPON_CODES
  const indirectLoyaltyIntegrationPos =
    redemptionProvider.integration_type === 'indirect'

  useEffect(() => {
    if (isCouponCode && couponCodes?.generationType) {
      dispatch(getMerchantCouponPools(couponCodes.generationType))
    }
  }, [dispatch, couponCodes?.generationType, isCouponCode])

  const types = typeItem ? typeItem.types : []
  const hasAutomaticType =
    types.includes('automatic/amount') || types.includes('automatic/percent')
  const isBogoSubtype =
    typeItem?.subtype === 'bogo/purchase' || typeItem?.subtype === 'bogo/item'
  const displayAutoCashback =
    merchant?.automatic_capable && hasAutomaticType && !isBogoSubtype

  return (
    <div className={'card mb-xl p-m white-bkg'}>
      <div className={'card-body mb-m'}>
        <FormsyRadioGroup
          // @ts-ignore
          name="instoreRedemptionType"
          value={instoreRedemptionType}
        >
          <Radio
            className="w-100"
            overrides={{
              Label: {
                style: {
                  width: '100%',
                },
              },
              Root: {
                style: {
                  alignItems: 'flex-start',
                },
              },
            }}
            value={InstoreRedemptionType.MANAGER_COMP}
            disabled={indirectLoyaltyIntegrationPos}
          >
            <Text.BodyText3 tag="p" color="grey70" className="mb-xs">
              {t('type.manager_comp')}
              <Switch condition={indirectLoyaltyIntegrationPos}>
                <PopoverInfo
                  text={t('manager_comp_unavailable')}
                  className="ml-xs"
                />
              </Switch>
            </Text.BodyText3>
            <Text.BodyText4 tag="p" color="grey70" className="mb-xs">
              {t('type.manager_comp_info')}
            </Text.BodyText4>
          </Radio>
          <Radio value={InstoreRedemptionType.COUPON_CODES}>
            {t('type.coupon_codes_enforced_by_pos')}
          </Radio>
          <Switch
            condition={
              instoreRedemptionType === InstoreRedemptionType.COUPON_CODES
            }
          >
            <Switch
              condition={isLoyaltyIntegrationPos(redemptionProvider)}
              fallback={
                <>
                  <CouponCodesForm
                    selectedCouponPools={selectedCouponPools}
                    template={template}
                    className={css({ cursor: 'auto' })}
                    redemptionProvider={redemptionProvider}
                  />
                </>
              }
            >
              <ThanxGeneratedEnabled
                typeItem={typeItem}
                template={template}
                redemptionProvider={redemptionProvider}
              />
            </Switch>
          </Switch>
          <Radio
            value={InstoreRedemptionType.CASH_BACK}
            disabled={!displayAutoCashback}
          >
            {t('type.automatic_cash_back')}
            <Switch condition={!displayAutoCashback}>
              <PopoverInfo
                text={t('automatic_unavailable')}
                className="ml-xs"
              />
            </Switch>
          </Radio>
        </FormsyRadioGroup>
      </div>
    </div>
  )
}

export default Form
