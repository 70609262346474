import { FormsyInput } from '@thanx/uikit/input'
import { FormsySelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { useMemo } from 'react'

import type { AppLink } from './AppFields'

import type { Fields as Merchant } from 'models/Merchant'
import type { FormModel } from 'scenes/Cms/Settings/SmartLinks/LinkGenerationForm'

const t = buildTranslate('cms.settings.smart_links.web_fallback')
const smartLinksT = buildTranslate('cms.settings.smart_links')

export type WebFallback = 'store' | 'ordering' | 'custom'
type WebFallbackOptions = Array<{ label: string; value: WebFallback }>

const appLinkToWebLocationMap: Record<AppLink, WebOrderingLocation> = {
  about: 'home-page',
  account: 'account',
  homePage: 'home-page',
  locations: 'home-page',
  notifications: 'home-page',
  nps: 'home-page',
  orderHistory: 'order-history',
  orderingMenu: 'ordering-menu',
  productDetails: 'items-on-the-menu',
  registeredCards: 'saved-cards',
  rewards: 'rewards',
  support: 'home-page',
  earn: 'rewards',
}

const getWebFallbackOptions = (
  merchant: Merchant | null
): WebFallbackOptions => {
  const { web_ordering_enabled } = merchant || {}

  const webFallbackOptions: WebFallbackOptions = [
    { label: t('fallback_labels.app_store'), value: 'store' },
    { label: t('fallback_labels.web_ordering'), value: 'ordering' },
    { label: t('fallback_labels.custom_url'), value: 'custom' },
  ]

  if (web_ordering_enabled) {
    return webFallbackOptions
  }

  return webFallbackOptions.filter(option => option.value !== 'ordering')
}

export type WebOrderingLocation =
  | 'home-page'
  | 'rewards'
  | 'account'
  | 'saved-cards'
  | 'order-history'
  | 'ordering-menu'
  | 'items-on-the-menu'

const defaultWebOrderingLocations: {
  label: string
  value: WebOrderingLocation
}[] = [
  {
    label: t('web_ordering_locations.home_page'),
    value: 'home-page',
  },
  {
    label: t('web_ordering_locations.rewards'),
    value: 'rewards',
  },
  {
    label: t('web_ordering_locations.account'),
    value: 'account',
  },
  {
    label: t('web_ordering_locations.saved_cards'),
    value: 'saved-cards',
  },
  {
    label: t('web_ordering_locations.order_history'),
    value: 'order-history',
  },
]

type Props = {
  className?: string
  formModel: FormModel
  availableFallbacks: WebFallback[]
  name: string
  label: string
  menuCategoryOptions: { label: string; value: string }[]
  productOptions: { label: string; value: string }[]
}

const WebFields = (props: Props) => {
  const {
    className,
    formModel,
    availableFallbacks,
    name,
    label,
    menuCategoryOptions,
    productOptions,
  } = props

  const merchant = useCurrentMerchant()

  const webFallbackOptions = getWebFallbackOptions(merchant)
  const fallbackOptions = webFallbackOptions.filter(o =>
    availableFallbacks.includes(o.value)
  )

  const webOrderingLocations = useMemo(() => {
    let locations = [...defaultWebOrderingLocations]

    if (menuCategoryOptions.length) {
      locations.push({
        label: t('web_ordering_locations.ordering_menu'),
        value: 'ordering-menu',
      })
    }

    if (productOptions.length) {
      locations.push({
        label: t('web_ordering_locations.items_on_the_menu'),
        value: 'items-on-the-menu',
      })
    }

    return locations
  }, [menuCategoryOptions, productOptions])

  const getFieldValue = ({
    fieldName,
    defaultValue,
  }: {
    fieldName: string
    defaultValue: string | null
  }) => formModel[fieldName] ?? defaultValue

  const getFromAppLink = (
    appLinkField: string,
    options: Array<{ value: string }>
  ): string | null => {
    const appLinkValue = formModel[appLinkField]
    const optionValues = options.map(option => option.value)
    return appLinkValue && optionValues.includes(appLinkValue)
      ? appLinkValue
      : null
  }

  const selectedAppLink = formModel['appLink']
  const webLocationFromAppLink = selectedAppLink
    ? appLinkToWebLocationMap[selectedAppLink]
    : null

  const orderingCategoryFromAppLink = getFromAppLink(
    'appLinkCategory',
    menuCategoryOptions
  )
  const productFromAppLink = getFromAppLink('appLinkProduct', productOptions)

  const selectedFallback = formModel[`${name}Fallback`]

  return (
    <div className={className}>
      <FormsySelect
        label={label}
        name={`${name}Fallback`}
        options={fallbackOptions}
        validations={{
          isRequired: true,
        }}
        validationErrors={{
          isRequired: buildTranslate('cms.settings.smart_links')('required'),
        }}
      />
      <Switch condition={selectedFallback === 'custom'}>
        <FormsyInput
          className="mt-m"
          name={`${name}CustomUrl`}
          placeholder={t('custom_url_placeholder')}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: smartLinksT('required'),
          }}
        />
      </Switch>

      <Switch condition={selectedFallback === 'ordering'}>
        <FormsySelect
          aria-label={t('labels.select_ordering_location')}
          className="mt-s"
          name={`${name}WebOrderingLocation`}
          options={webOrderingLocations}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: smartLinksT('required'),
          }}
          value={getFieldValue({
            fieldName: `${name}WebOrderingLocation`,
            defaultValue: webLocationFromAppLink,
          })}
        />
      </Switch>

      <Switch
        condition={
          selectedFallback === 'ordering' &&
          formModel[`${name}WebOrderingLocation`] === 'ordering-menu'
        }
      >
        <FormsySelect
          aria-label={t('labels.select_category')}
          className="mt-s"
          name={`${name}WebOrderingCategory`}
          placeholder={t('category_placeholder')}
          options={menuCategoryOptions}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: smartLinksT('required'),
          }}
          value={getFieldValue({
            fieldName: `${name}WebOrderingCategory`,
            defaultValue: orderingCategoryFromAppLink,
          })}
        />
      </Switch>

      <Switch
        condition={
          selectedFallback === 'ordering' &&
          formModel[`${name}WebOrderingLocation`] === 'items-on-the-menu'
        }
      >
        <FormsySelect
          aria-label={t('labels.select_product')}
          className="mt-s"
          name={`${name}WebOrderingProduct`}
          placeholder={t('product_placeholder')}
          options={productOptions}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: smartLinksT('required'),
          }}
          value={getFieldValue({
            fieldName: `${name}WebOrderingProduct`,
            defaultValue: productFromAppLink,
          })}
        />
      </Switch>
    </div>
  )
}

export default WebFields
