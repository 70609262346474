import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import { Container } from 'react-bootstrap-five'
import Tab from 'react-bootstrap-five/Tab'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'

const t = buildTranslate('thanx_campaigns.performance')
const tError = buildTranslate('errors')
const tLooker = buildTranslate('looker')

export enum Tabs {
  EMAIL_ENGAGEMENT_OVERVIEW = 'email_engagement_overview',
  EMAIL_ENGAGEMENT_BY_CAMPAIGN_TYPE = 'email_engagement_by_campaign_type',
}

interface CampaignPerformanceTabsProps {
  activeTab: Tabs
  setActiveTab: (tab: Tabs) => void
  emailEngagementOverviewContent: React.ReactNode
  userIsAdmin: boolean
  merchant: Merchant
  emailEngagementByCampaignTypePath: string
  emailEngagementByCampaignTypeHeight: number
}

const CampaignPerformanceTabs = ({
  activeTab,
  setActiveTab,
  emailEngagementOverviewContent,
  userIsAdmin,
  merchant,
  emailEngagementByCampaignTypePath,
  emailEngagementByCampaignTypeHeight,
}: CampaignPerformanceTabsProps) => {
  const [css] = useStyletron()

  return (
    <Tab.Container
      activeKey={activeTab}
      transition={false}
      onSelect={(tab: string | null) => {
        if (!tab) return
        setActiveTab(tab as Tabs)
      }}
    >
      <TabNav>
        <TabItem
          state={Tabs.EMAIL_ENGAGEMENT_OVERVIEW}
          title={tLooker(`urls.dashboards/269.tabs.email_engagement_overview`)}
        />
        <TabItem
          state={Tabs.EMAIL_ENGAGEMENT_BY_CAMPAIGN_TYPE}
          title={tLooker(
            `urls.dashboards/269.tabs.email_engagement_by_campaign_type`
          )}
        />
      </TabNav>
      <Tab.Content className="pb-l h-100 grey-05-bkg">
        <TabPane state={Tabs.EMAIL_ENGAGEMENT_OVERVIEW}>
          {emailEngagementOverviewContent}
        </TabPane>
        <TabPane state={Tabs.EMAIL_ENGAGEMENT_BY_CAMPAIGN_TYPE}>
          <div className="pb-l grey-05-bkg">
            <div className="container pl-0 pr-0">
              <Text.Header3 className="mt-l mb-xl" tag="div">
                {t('emailEngagementByCampaignType.title')}
              </Text.Header3>
              <Container
                className={`px-0 ${css({
                  minHeight: `${emailEngagementByCampaignTypeHeight + 300}px`,
                })}`}
              >
                <Switch
                  condition={userIsAdmin}
                  fallback={
                    <Text.BodyText3 className="mb-xl">
                      {tError('forbidden_title')}
                    </Text.BodyText3>
                  }
                >
                  <LookerIframe
                    path={emailEngagementByCampaignTypePath}
                    merchant={merchant}
                    height={`${emailEngagementByCampaignTypeHeight}px`}
                  />
                  <LookerFooter className="my-xl" />
                </Switch>
              </Container>
            </div>
          </div>
        </TabPane>
      </Tab.Content>
    </Tab.Container>
  )
}

export default CampaignPerformanceTabs
