import { Input } from '@thanx/uikit/input'
import Cell from 'components/Cell'
import { useStyletron } from 'styletron-react'
import { getMergeTagTypeName, MergeTagItem } from 'utilities/mergeTags'

type MergeTagTableRowProps = {
  mergeTagItem: MergeTagItem
  onInputChange: (id: number, newValue: string) => void
}

const MergeTagTableRow: React.FC<MergeTagTableRowProps> = ({
  mergeTagItem,
  onInputChange,
}: MergeTagTableRowProps) => {
  const { id, mergeTagType, defaultValue } = mergeTagItem
  const [css] = useStyletron()

  const defaultValueCellContent = (
    <Input
      name={mergeTagType}
      value={defaultValue}
      onChange={value => onInputChange(id, value)}
    />
  )
  const mergeTagTypeName = getMergeTagTypeName(mergeTagType)
  return (
    <tr
      key={id}
      className="border-bottom-1 grey-30-border pb-m"
      data-testid={`merge-tag-table-row`}
    >
      <Cell
        className={`${css({
          width: '180px',
        })}`}
      >
        {mergeTagTypeName}
      </Cell>
      <Cell
        className={`${css({
          width: '250px',
          paddingRight: '0 !important',
        })}`}
      >
        {defaultValueCellContent}
      </Cell>
    </tr>
  )
}

export default MergeTagTableRow
