export const unlayerCustomFonts = [
  {
    label: 'Barlow',
    value: "'Barlow Condensed', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Barlow+Condensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Rethink Sans',
    value: "'Rethink Sans', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Rethink+Sans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
]
