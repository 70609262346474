import useFlag from 'hooks/useFlag'
import urlUtils from 'utilities/urlUtils'
import CustomPrivacy from './CustomPrivacy'
import ThanxPrivacy from './ThanxPrivacy'

function Privacy() {
  const url: any = urlUtils.matchPath('/privacy/:handle', true)
  const handle = url?.params.handle

  const customizableLegalDocsFlag = useFlag('mx-customizable-legal-docs', false)

  if (customizableLegalDocsFlag && handle) {
    return <CustomPrivacy handle={handle} testId="custom-privacy" />
  }

  return <ThanxPrivacy handle={handle} testId="thanx-privacy" />
}

export default Privacy
