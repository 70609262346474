import { api } from './index'

export type MergeTag = {
  id: number
  merge_tag_type: string
  default_value: string
  merchant_id?: number
}

type GetMergeTagsResponse = { merge_tags: MergeTag[] }

type GetMergeTagResponse = { merge_tag: MergeTag }

export type UpdateMergeTagPayload = Partial<
  Pick<MergeTag, 'default_value' | 'id'>
>

export type UpdateMergeTagsPayload = {
  merge_tag_defaults: UpdateMergeTagPayload[]
}

export const mergeTagsApi = api.injectEndpoints({
  endpoints: builder => ({
    getMergeTags: builder.query<GetMergeTagsResponse, void>({
      query: () => {
        return {
          url: 'merge_tags',
        }
      },
      providesTags: ['MergeTag'],
    }),

    getMergeTag: builder.query<GetMergeTagResponse, string>({
      query: id => ({ url: `merge_tags/${id}` }),
      providesTags: ['MergeTag'],
    }),

    updateMergeTag: builder.mutation<
      GetMergeTagResponse,
      UpdateMergeTagPayload
    >({
      query: patch => {
        const { id, ...rest } = patch

        return {
          url: `merge_tags/${id}`,
          method: 'PATCH',
          body: rest,
        }
      },
      invalidatesTags: ['MergeTag'],
    }),

    updateMergeTags: builder.mutation<
      GetMergeTagsResponse,
      UpdateMergeTagsPayload
    >({
      query: patch => {
        return {
          url: 'merge_tags',
          method: 'PATCH',
          body: patch,
        }
      },
      invalidatesTags: ['MergeTag'],
    }),
  }),
})
